import API from "../../../../../global/api";
import jsPDF from "jspdf";
// import moment from "moment";
import _ from "lodash";
import logo from "../../../../../Images/logo.png";


export const requestAddInsuranceService = () => {
    return {
        type: "REQUEST_ADD_INSURANCE_SERVICE",
    };
};
export const successAddInsuranceService = (data) => {
    return {
        type: "SUCCESS_ADD_INSURANCE_SERVICE",
        payload: data,
    };
};
export const errorAddInsuranceService = () => {
    return {
        type: "ERROR_ADD_INSURANCE_SERVICE",
    };
};
export const AddInsuranceServiceApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddInsuranceService());
        API.post(`/insuranceService`, data).then((res) => {
            dispatch(successAddInsuranceService(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddInsuranceService());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestInsuranceServiceData = () => {
    return {
        type: "REQUEST_GET_INSURANCE_SERVICE",
    };
};
export const successInsuranceServiceData = (data) => {
    return {
        type: "SUCCESS_GET_INSURANCE_SERVICE",
        payload: data,
    };
};
export const errorInsuranceServiceData = () => {
    return {
        type: "ERROR_GET_INSURANCE_SERVICE",
    };
};
export const GetInsuranceServiceData = () => {
    return (dispatch) => {
        dispatch(requestInsuranceServiceData());
        API.get(`/insuranceService`).then((res) => {
            let getData = res.data;
            dispatch(successInsuranceServiceData(getData));
        }).catch((error) => {
            dispatch(errorInsuranceServiceData());
        })
    };
};


export const requestInsuranceServiceDataWithId = () => {
    return {
        type: "REQUEST_GET_INSURANCE_SERVICE_WITH_ID",
    };
};
export const successInsuranceServiceDataWithId = (data) => {
    return {
        type: "SUCCESS_GET_INSURANCE_SERVICE_WITH_ID",
        payload: data,
    };
};
export const errorInsuranceServiceDataWithId = () => {
    return {
        type: "ERROR_GET_INSURANCE_SERVICE_WITH_ID",
    };
};
export const GetInsuranceServiceWithIdApi = (id) => {
    return (dispatch) => {
        dispatch(requestInsuranceServiceDataWithId());
        API.get(`/insuranceService/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successInsuranceServiceDataWithId(getData));
        }).catch((error) => {
            dispatch(errorInsuranceServiceDataWithId());
        })
    };
};


export const RequestDeleteInsuranceService = () => {
    return {
        type: "DELETE_REQUEST_INSURANCR_SERVICE",
    };
};
export const SucessDeleteInsuranceService = (user_id) => {
    return {
        type: "DELETE_SUCCESS_INSURANCR_SERVICE",
        user_id: user_id,
    };
};
export const ErrorDeleteInsuranceService = () => {
    return {
        type: "DELETE_ERROR_INSURANCR_SERVICE",
    };
};
export const DeleteInsuranceServiceApi = (id) => {
    return dispatch => {
        dispatch(RequestDeleteInsuranceService());
        API.delete(`/insuranceService/${id}`).then(res => {
            dispatch(SucessDeleteInsuranceService(id))
        }).catch((error) => {
            dispatch(ErrorDeleteInsuranceService())
        });
    }
};


export const RequestUpdateServiceStatus = () => {
    return {
        type: "UPDATE_REQUEST_STATUS",
    };
};
export const SucessUpdateServiceStatus = (data) => {
    return {
        type: "UPDATE_SUCCESS_STATUS",
        payload: data,
    };
};
export const ErrorUpdateServiceStatus = () => {
    return {
        type: "UPDATE_ERROR_STATUS",
    };
};
export const UpdateServiceStatusApi = (status, id) => {
    return dispatch => {
        dispatch(RequestUpdateServiceStatus());
        API.put(`/insuranceService/update_service_status/${id}`, {
            status: status,
        }, {
        }).then(res => {
            dispatch(SucessUpdateServiceStatus([status]))
        }).catch((error) =>
            dispatch(ErrorUpdateServiceStatus()));
    }
};


export const InsuranceServicePDF = (data) => {
    return (dispatch) => {
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const title = "Insurance Service Details";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        const srNo = "Sr No: " + data.seqNumber;
        const serviceType = "Service Type: " + data.serviceType;
        const businessName = "Business Location: " + data.businessLocationDetails.businessName;
        const status = "Status: " + data.status;
        const vehicleNumber = "Vehicle Number: " + data.vehicleNumber;
        const vehicleMade = "Vehicle Made: " + data.vehicleMade;
        const vehicleModel = "Vehicle Model: " + data.vehicleModel;
        const customerName = "Customer Name: " + data.customerName;
        const customerNumber = "Customer Number: " + data.customerNumber;
        const customerEmail = "Customer Email: " + data.customerEmail;
        const employeeName = "Employee Name: " + data.employeeName;
        const date = "Date: " + data.date;
        const expenseTotal = "Expense Total: " + data.totalExpense;


        const headers = [[
            "Sr",
            "Product Name",
            "Quantity",
            "Price",
            "Total Sale Value",
        ]];
        const prodactData = _.isEmpty(data.productExpenseData) ? '' : data.productExpenseData.map((elt, i) => [
            i + 1,
            elt.productName,
            elt.quantity,
            elt.salePrice,
            elt.totalSalePrice,
        ]);
        let ProductContent = {
            startY: 290,
            startX: 50,
            head: headers,
            styles: { fontSize: 11 },
            body: prodactData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba" },
        };
        doc.autoTable(ProductContent);
        const ServiceHeaders = [[
            "Sr",
            "Service Name",
            "Quantity",
            "Price",
            "Total Sale Value",
        ]];
        const ServiceData = _.isEmpty(data.serviceExpenseData) ? '' : data.serviceExpenseData.map((elt, i) => [
            i + 1,
            elt.productName,
            elt.quantity,
            elt.salePrice,
            elt.totalSalePrice,
        ]);
        let ServiceContent = {
            startY: doc.lastAutoTable.finalY + 20,
            startX: 50,
            head: ServiceHeaders,
            styles: { fontSize: 11 },
            body: ServiceData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba" },
        };
        doc.autoTable(ServiceContent);


        doc.addImage(logo, "PNG", 40, 15, 80, 80);
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(13);
        doc.setFont("times", "normal");
        doc.text(companyAddress, 150, 60, 0, 20);
        doc.text(companyNtn, 150, 80, 0, 20);
        doc.setFontSize(14);
        doc.setFont("times", "bold");
        doc.text(title, 210, 120);
        doc.setFont("times", "normal");
        doc.text(srNo, 50, 150, 0, 20);
        doc.text(serviceType, 330, 150, 0, 20);
        doc.text(businessName, 50, 170, 0, 20);
        doc.text(status, 330, 170, 0, 20);
        doc.text(vehicleNumber, 50, 190, 0, 20);
        doc.text(vehicleMade, 330, 190, 0, 20);
        doc.text(vehicleModel, 50, 210, 0, 20);
        doc.text(customerName, 330, 210, 0, 20);
        doc.text(customerNumber, 50, 230, 0, 20);
        doc.text(customerEmail, 330, 230, 0, 20);
        doc.text(employeeName, 50, 250, 0, 20);
        doc.text(date, 330, 250, 0, 20);
        doc.text(expenseTotal, 50, 270, 0, 20);


        doc.setProperties({ title: `Insurance Service Detail For "${data.vehicleNumber}"` });
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
    };
};


export const requestAddInsuranceGatePass = () => {
    return {
        type: "REQUEST_ADD_INSURANCE_GATEPASS",
    };
};
export const successAddInsuranceGatePass = (data) => {
    return {
        type: "SUCCESS_ADD_INSURANCE_GATEPASS",
        payload: data,
    };
};
export const errorAddInsuranceGatePass = () => {
    return {
        type: "ERROR_ADD_INSURANCE_GATEPASS",
    };
};
export const AddInsuranceGatePassApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddInsuranceGatePass());
        API.post(`/insuranceService/add_gatePass_details`, data).then((res) => {
            dispatch(successAddInsuranceGatePass(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddInsuranceGatePass());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestAddInsuranceServiceDeliveryPass = () => {
    return {
        type: "REQUEST_ADD_INSURANCE_SERVICE_DELIVERY_PASS",
    };
};
export const successAddInsuranceServiceDeliveryPass = (data) => {
    return {
        type: "SUCCESS_ADD_INSURANCE_SERVICE_DELIVERY_PASS",
        payload: data,
    };
};
export const errorAddInsuranceServiceDeliveryPass = () => {
    return {
        type: "ERROR_ADD_INSURANCE_SERVICE_DELIVERY_PASS",
    };
};
export const AddInsuranceDeliveryPassApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddInsuranceServiceDeliveryPass());
        API.post(`/insuranceService/add_deliveryPass`, data).then((res) => {
            dispatch(successAddInsuranceServiceDeliveryPass(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddInsuranceServiceDeliveryPass());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestAddCashServiceExpense = () => {
    return {
        type: "REQUEST_ADD_INSURANCE_SERVICE_EXPENSE",
    };
};
export const successAddCashServiceExpense = (data) => {
    return {
        type: "SUCCESS_ADD_INSURANCE_SERVICE_EXPENSE",
        payload: data,
    };
};
export const errorAddCashServiceExpense = () => {
    return {
        type: "ERROR_ADD_INSURANCE_SERVICE_EXPENSE",
    };
};
export const AddInsuranceExpenseApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddCashServiceExpense());
        API.post(`/insuranceService/add_expense`, data).then((res) => {
            dispatch(successAddCashServiceExpense(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddCashServiceExpense());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const resetReducer = () => {
    return {
        type: "RESET_URL",
    };
};


export const requestIncServiceWithStatus = () => {
    return {
        type: "REQUEST_SEARCH_INC_SERVICE_WITH_STATUS",
    };
};
export const successIncServiceWithStatus = (data) => {
    return {
        type: "SUCCESS_SEARCH_INC_SERVICE_WITH_STATUS",
        payload: data,
    };
};
export const errorIncServiceWithStatus = () => {
    return {
        type: "ERROR_SEARCH_INC_SERVICE_WITH_STATUS",
    };
};
export const GetIncServiceWithStatusApi = (data, startDate, endDate) => {
    return (dispatch) => {
        dispatch(requestIncServiceWithStatus());
        API.post(`/insuranceService/get_IncService_with_status`, data).then((res) => {
            let getData = res.data;
            dispatch(successIncServiceWithStatus(getData));
        }).catch((error) => {
            dispatch(errorIncServiceWithStatus());
        });
    };
};
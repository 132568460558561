import API from "../../../../../global/api";


export const requestGetPermissions = () => {
  return {
    type: "REQUEST_GET_Permissions",
  };
};
export const successGetPermissions = (data) => {
  return {
    type: "SUCCESS_GET_Permissions",
    payload: data,
  };
};
export const errorGetPermissions = () => {
  return {
    type: "ERROR_GET_Permissions",
  };
};
export const getPermissions = () => {
  return (dispatch) => {
    dispatch(requestGetPermissions());
    API.get(`/permission`).then((res) => {
      let getData = res.data;
      dispatch(successGetPermissions(getData));
    }).catch((error) => {
      dispatch(errorGetPermissions());
    })
  };
};


export const requestAddPermissions = () => {
  return {
    type: "REQUEST_ADD_PERMISSION",
  };
};
export const successAddPermissions = (data) => {
  return {
    type: "SUCCESS_ADD_PERMISSION",
    payload: data,
  };
};
export const errorAddPermissions = () => {
  return {
    type: "ERROR_ADD_PERMISSION",
  };
};
export const addPermissions = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddPermissions());
    API.post(`/permission/add_assigned_permission`, data).then((res) => {
      let getData = res.data;
      dispatch(successAddPermissions(getData));
      handleVisible(res.data.message);
    }).catch((error) => {
      handleDangerVisible();
      dispatch(errorAddPermissions(error.response.data.message));
    })
  };
};
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import userReducer from "../views/user_views/accounts/account.reducers";
import UserReducer from "../views/user_views/forms/setting/AddUser/user.reducers";
import CustomerReducer from "../views/user_views/forms/AddDetails/AddCustomer/Customer.reducer";
import BackUpReducer from "../views/user_views/forms/setting/BackUp/BackUp.reducers";
import PermissionReducer from "../views/user_views/forms/MasterControl/Permissions/PermissionsReducer";
import UploadImageReducer from "../views/user_views/forms/setting/uploadImage/uploadImageReducer";
import CompanyReducer from "../views/user_views/forms/setting/Company/companyReducer";
import WarehouseReducer from "../views/user_views/forms/setting/BusinessLocation/BusinessLocationReducer";
import ServiceReducer from "../views/user_views/forms/MasterControl/Service/serviceReducer";
import ProductReducer from "../views/user_views/forms/MasterControl/Product/productReducer";
import InsuranceCompanyReducer from "../views/user_views/forms/MasterControl/InsuranceCompany/insuranceCompanyReducer";
import InspectorReducer from "../views/user_views/forms/MasterControl/Inspector/inspectorReducer";
import CashServiceReducer from "../views/user_views/forms/MainServices/CashService/cashServiceReducer";
import InsuranceServiceReducer from "../views/user_views/forms/MainServices/InsuranceService/insuranceServiceReducer";
import PurchaseReducer from "../views/user_views/forms/Store/PurchaseOrder/PurchaseOrderReducer";
import StoreLegderReducer from "../views/user_views/forms/Store/StoreLedger/StoreLedgerReducer";


const AllReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    userReducer,
    PermissionReducer,
    UserReducer,
    CustomerReducer,
    BackUpReducer,
    UploadImageReducer,
    CompanyReducer,
    WarehouseReducer,
    ServiceReducer,
    ProductReducer,
    InsuranceCompanyReducer,
    InspectorReducer,
    CashServiceReducer,
    InsuranceServiceReducer,
    PurchaseReducer,
    StoreLegderReducer
  });


export default AllReducers
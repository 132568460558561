const initialState = {
  insuranceServiceList: [],
  insuranceServiceDataList: [],
  isFetchingInsuranceService: false,
  isAddingInsuranceService: false,
  isFetchingInsuranceServiceWithId: false,
  insuranceServiceDataListWithId: [],
  isAddingInsuranceGatePass: false,
  insuranceServiceGatePass: [],
  isAddingInsuranceDeliveryPass: false,
  insuranceDeliveryPass: [],
  isAddingInsuranceServiceExpense: false,
  InsuranceServiceExpense: [],

  isFetchingIncServiceWithStatus: false,
  incServiceWithStatus: [],
};

const InsuranceServiceReducer = (state = initialState, action) => {
  switch (action.type) {

    case "REQUEST_ADD_INSURANCE_SERVICE":
      return { ...state, isAddingInsuranceService: true };
    case "SUCCESS_ADD_INSURANCE_SERVICE":
      return { ...state, insuranceServiceList: action.payload, isAddingInsuranceService: false, };
    case "ERROR_ADD_INSURANCE_SERVICE":
      return { ...state, isAddingInsuranceService: false };


    case "REQUEST_ADD_INSURANCE_SERVICE_DELIVERY_PASS":
      return { ...state, isAddingInsuranceDeliveryPass: true };
    case "SUCCESS_ADD_INSURANCE_SERVICE_DELIVERY_PASS":
      return { ...state, insuranceDeliveryPass: action.payload, isAddingInsuranceDeliveryPass: false, };
    case "ERROR_ADD_INSURANCE_SERVICE_DELIVERY_PASS":
      return { ...state, isAddingInsuranceDeliveryPass: false };


    case "REQUEST_ADD_INSURANCE_GATEPASS":
      return { ...state, isAddingInsuranceGatePass: true };
    case "SUCCESS_ADD_INSURANCE_GATEPASS":
      return { ...state, insuranceServiceGatePass: action.payload, isAddingInsuranceGatePass: false, };
    case "ERROR_ADD_INSURANCE_GATEPASS":
      return { ...state, isAddingInsuranceGatePass: false };


    case "REQUEST_GET_INSURANCE_SERVICE":
      return { ...state, isFetchingInsuranceService: true, };
    case "SUCCESS_GET_INSURANCE_SERVICE":
      return { ...state, insuranceServiceDataList: action.payload.data, isFetchingInsuranceService: false };
    case "ERROR_GET_INSURANCE_SERVICE":
      return { ...state, };


    case "REQUEST_GET_INSURANCE_SERVICE_WITH_ID":
      return { ...state, isFetchingInsuranceServiceWithId: true, };
    case "SUCCESS_GET_INSURANCE_SERVICE_WITH_ID":
      return { ...state, insuranceServiceDataListWithId: action.payload.data, isFetchingInsuranceServiceWithId: false, };
    case "ERROR_GET_INSURANCE_SERVICE_WITH_ID":
      return { ...state, };


    case "REQUEST_ADD_INSURANCE_SERVICE_EXPENSE":
      return { ...state, isAddingInsuranceServiceExpense: true };
    case "SUCCESS_ADD_INSURANCE_SERVICE_EXPENSE":
      return { ...state, InsuranceServiceExpense: action.payload, isAddingInsuranceServiceExpense: false, };
    case "ERROR_ADD_INSURANCE_SERVICE_EXPENSE":
      return { ...state, isAddingInsuranceServiceExpense: false };


    case "REQUEST_SEARCH_INC_SERVICE_WITH_STATUS":
      return { ...state, isFetchingIncServiceWithStatus: true, };
    case "SUCCESS_SEARCH_INC_SERVICE_WITH_STATUS":
      return { ...state, incServiceWithStatus: action.payload.data, isFetchingIncServiceWithStatus: false, };
    case "ERROR_SEARCH_INC_SERVICE_WITH_STATUS":
      return { ...state, };


    default:
      return state;
  }
};
export default InsuranceServiceReducer;
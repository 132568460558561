import API from "../../../../../global/api";


export const requestAddService = () => {
    return {
        type: "REQUEST_ADD_SERVICE",
    };
};
export const successAddService = (data) => {
    return {
        type: "SUCCESS_ADD_SERVICE",
        payload: data,
    };
};
export const errorAddService = () => {
    return {
        type: "ERROR_ADD_SERVICE",
    };
};
export const AddServiceApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddService());
        API.post(`/service`, data).then((res) => {
            dispatch(successAddService(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddService());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestServiceData = () => {
    return {
        type: "REQUEST_SERVICE_GET_DATA",
    };
};
export const successServiceData = (data) => {
    return {
        type: "SUCCESS_SERVICE_GET_DATA",
        payload: data,
    };
};
export const errorServiceGetData = () => {
    return {
        type: "ERROR_SERVICE_GET_DATA",
    };
};
export const GetServiceApi = () => {
    return (dispatch) => {
        dispatch(requestServiceData());
        API.get(`/service?page=0&limit=1000`).then((res) => {
            let getData = res.data;
            dispatch(successServiceData(getData));
        }).catch((error) => {
            dispatch(errorServiceGetData());
        })
    };
};


export const DeleteRequestService = () => {
    return {
        type: "DELETE_REQUEST_SERVICE",
    };
};
export const DeleteSuccessService = (user_id) => {
    return {
        type: "DELETE_SUCCESS_SERVICE",
        user_id: user_id,
    };
};
export const DeleteErrorService = () => {
    return {
        type: "DELETE_ERROR_SERVICE",
    };
};
export const DeleteServiceApi = (id) => {
    return dispatch => {
        dispatch(DeleteRequestService());
        API.delete(`/service/${id}`).then(res => {
            dispatch(DeleteSuccessService(id))
        }).catch((error) => {
            dispatch(DeleteErrorService())
        });
    }
};


export const UpdateRequestService = () => {
    return {
        type: "UPDATE_REQUEST_SERVICE",
    };
};
export const UpdateSuccessService = (data) => {
    return {
        type: "UPDATE_SUCCESS_SERVICE",
        payload: data,
    };
};
export const UpdateErrorService = () => {
    return {
        type: "UPDATE_ERROR_SERVICE",
    };
};
export const UpdateServiceApi = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestService());
        API.put(`/service/${oldData._id}`, {
            serviceName: data.serviceName,
            description: data.description,
            price: data.price,
        }, {
        }).then(res => {
            dispatch(UpdateSuccessService([data]))
        }).catch((error) =>
            dispatch(UpdateErrorService()));
    }
};
import { filter, isEmpty, toLower } from "lodash";
const initialState = {
  productList: [],
  productDataList: [],
  isFetchingProduct: false,
  isAddingProduct: false,
  productSearchList: [],
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {

    case "REQUEST_ADD_PRODUCT":
      return { ...state, isAddingProduct: true };
    case "SUCCESS_ADD_PRODUCT":
      return { ...state, productList: action.payload, isAddingProduct: false, };
    case "ERROR_ADD_PRODUCT":
      return { ...state, isAddingProduct: false };


    case "REQUEST_PRODUCT_GET_DATA":
      return { ...state, isFetchingProduct: true, };
    case "SUCCESS_PRODUCT_GET_DATA":
      return { ...state, productDataList: action.payload.data, isFetchingProduct: false, };
    case "ERROR_PRODUCT_GET_DATA":
      return { ...state, };


    case "FILTER_PRODUCT":
      return {
        ...state,
        productSearchList: isEmpty(action.payload) ? state.productDataList : filter(state.productDataList, x => toLower(x.name).includes(toLower(action.payload)))
      }
    case "RESET_PRODUCT_URL":
      return { ...state, ProductImageURL: [] }


    default:
      return state;
  }
};
export default ProductReducer;
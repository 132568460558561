import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { NavLink } from "react-router-dom";
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import BusinessIcon from '@mui/icons-material/Business';
import BackupIcon from '@mui/icons-material/Backup';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import ManIcon from '@mui/icons-material/Man';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SecurityIcon from '@mui/icons-material/Security';
import AssessmentIcon from '@mui/icons-material/Assessment';



export default function Menu(props) {
  const [openCompany, setOpenCompany] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [backUp, setOpenBackup] = React.useState(false);
  const [businesslocation, setOpenBusinesslocation] = React.useState(false);
  const [service, setOpenService] = React.useState(false);
  const [product, setOpenProduct] = React.useState(false);
  const [insuranceCompany, setOpenInsuranceCompany] = React.useState(false);
  const [inspector, setOpenInspector] = React.useState(false);
  const [cashService, setOpenCashService] = React.useState(false);
  const [insuranceService, setOpenInsuranceService] = React.useState(false);
  const [purchaseOrder, setOpenPurchaseOrder] = React.useState(false);
  const [storeLedger, setOpenStoreLedger] = React.useState(false);



  const Checkpermissions = (permissionsType, permissionsName) => {
    return props.permissions.some(p => p[`${permissionsType}`] === permissionsName) ? true : false
  }


  // { MAIN SERVICES }
  let mainServiceList = <List>

    <ListItemButton onClick={() => setOpenCashService(!cashService)}>
      <ListItemIcon>
        <MonetizationOnIcon />
      </ListItemIcon>
      <ListItemText primary="Cash Service" />
      {cashService ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={cashService} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Cash/AddCashService" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Cash Service" />
          </ListItemButton>
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Cash/AddExpense" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Our Cost" />
          </ListItemButton>
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Cash/CompleteList" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Complete Service" />
          </ListItemButton>
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Cash/BillPaymentList" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Bill Payment" />
          </ListItemButton>
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Cash/AddDeliveryPass" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Deliver Pass" />
          </ListItemButton>
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Cash/Report" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Cash Service Report" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


    <ListItemButton onClick={() => setOpenInsuranceService(!insuranceService)}>
      <ListItemIcon>
        <AssuredWorkloadIcon />
      </ListItemIcon>
      <ListItemText primary="Insurance Service" />
      {insuranceService ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={insuranceService} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/AddInsuranceService" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Insurance Service" />
          </ListItemButton>
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/AddGatePass" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Gate Pass" />
          </ListItemButton>
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/AddExpense" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Our Cost" />
          </ListItemButton>
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/InspectionList" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Inspection List" />
          </ListItemButton>
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/CompleteList" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Complete Service" />
          </ListItemButton>
        </NavLink>
      </List>
      {/* <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/BillPaymentList" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Bill Payment" />
          </ListItemButton>
        </NavLink>
      </List> */}
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/AddDeliveryPass" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Deliver Pass" />
          </ListItemButton>
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/Report" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Inc Service Report" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>

  </List>;


  // { MASTER CONTROL }
  let masterControlList = <List>
    <ListItemButton onClick={() => setOpenCompany(!openCompany)}>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <ListItemText primary="Company" />
      {openCompany ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={openCompany} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/setting/Company/AddCompany" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Company" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/setting/Company/ListCompany" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Company List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


    <ListItemButton onClick={() => setOpenBusinesslocation(!businesslocation)}>
      <ListItemIcon>
        <AddBusinessIcon />
      </ListItemIcon>
      <ListItemText primary="Business Location" />
      {businesslocation ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={businesslocation} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/setting/BusinessLocation/AddBusinessLocation" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Business Location" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/setting/BusinessLocation/ListBusinessLocation" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Business Location List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


    <ListItemButton onClick={() => setOpenUser(!openUser)}>
      <ListItemIcon>
        <PersonAddAlt1Icon />
      </ListItemIcon>
      <ListItemText primary="User" />
      {openUser ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={openUser} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/setting/User/AddUser" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="New User" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/setting/user/ListUser" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="User List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


    <ListItemButton onClick={() => setOpenService(!service)}>
      <ListItemIcon>
        <SettingsSuggestIcon />
      </ListItemIcon>
      <ListItemText primary="Service" />
      {service ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={service} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/masterControl/service/AddService" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Service" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/masterControl/service/ListService" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Service List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


    <ListItemButton onClick={() => setOpenInsuranceCompany(!insuranceCompany)}>
      <ListItemIcon>
        <BeenhereIcon />
      </ListItemIcon>
      <ListItemText primary="Insurance Company" />
      {insuranceCompany ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={insuranceCompany} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/masterControl/insuranceCompany/AddInsuranceCompany" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Insurance Company" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/masterControl/insuranceCompany/ListInsuranceCompany" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Insurance Company List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


    <ListItemButton onClick={() => setOpenInspector(!inspector)}>
      <ListItemIcon>
        <ManIcon />
      </ListItemIcon>
      <ListItemText primary="Inspector" />
      {inspector ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={inspector} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/masterControl/inspector/AddInspector" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Inspector" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/masterControl/inspector/ListInspector" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Inspector List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


    {/* <NavLink to="/setting/permission/AddPermission" style={{ textDecoration: 'none', color: 'black' }}>
      <ListItemButton>
        <ListItemIcon>
          <SecurityIcon />
        </ListItemIcon>
        <ListItemText primary="Permission" />
      </ListItemButton>
    </NavLink> */}


    <ListItemButton onClick={() => setOpenBackup(!backUp)}>
      <ListItemIcon>
        <BackupIcon />
      </ListItemIcon>
      <ListItemText primary="Backup" />
      {backUp ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={backUp} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/setting/BackUp" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Backup" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>

  </List>;


  // { STORE }
  let StoreList = <List>
    <ListItemButton onClick={() => setOpenProduct(!product)}>
      <ListItemIcon>
        <Inventory2Icon />
      </ListItemIcon>
      <ListItemText primary="Product" />
      {product ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={product} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/store/product/AddProduct" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Product" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/store/product/ListProduct" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Product List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>



    <ListItemButton onClick={() => setOpenPurchaseOrder(!purchaseOrder)}>
      <ListItemIcon>
        <ShoppingBasketIcon />
      </ListItemIcon>
      <ListItemText primary="Purchase Order" />
      {purchaseOrder ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={purchaseOrder} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/store/PurchaseOrder/AddPurchaseOrder" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Purchase Order" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/store/PurchaseOrder/ViewPurchaseOrder" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Purchase Order List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


    <NavLink to="/store/storeLedger" style={{ textDecoration: 'none', color: 'black' }}>
      <ListItemButton>
        <ListItemIcon>
          <SummarizeIcon />
        </ListItemIcon>
        <ListItemText primary="Store Ledger" />
      </ListItemButton>
    </NavLink>



  </List>


  if (props.moduleKey === 'mainService' || window.localStorage.getItem('module_key') === 'mainService') return mainServiceList;
  else if (props.moduleKey === 'masterControl' || window.localStorage.getItem('module_key') === 'masterControl') return masterControlList;
  else if (props.moduleKey === 'store' || window.localStorage.getItem('module_key') === 'store') return StoreList;
  else return mainServiceList;
}; 

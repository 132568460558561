const initialState = {
    cashServiceList: [],
    cashServiceDataList: [],
    isFetchingCashService: false,
    isAddingCashService: false,
    isFetchingCashServiceWithId: false,
    cashServiceDataListWithId: [],
    isAddingCashDeliveryPass: false,
    cashDeliveryPass: [],
    isAddingCashServiceExpense: false,
    cashServiceExpense: [],
    isFetchingCashServiceWithStatus: false,
    cashServiceDataListWithStatus: [],
};

const CashServiceReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_CASH_SERVICE":
            return { ...state, isAddingCashService: true };
        case "SUCCESS_ADD_CASH_SERVICE":
            return { ...state, cashServiceList: action.payload, isAddingCashService: false, };
        case "ERROR_ADD_CASH_SERVICE":
            return { ...state, isAddingCashService: false };


        case "REQUEST_ADD_CASH_SERVICE_DELIVERY_PASS":
            return { ...state, isAddingCashDeliveryPass: true };
        case "SUCCESS_ADD_CASH_SERVICE_DELIVERY_PASS":
            return { ...state, cashDeliveryPass: action.payload, isAddingCashDeliveryPass: false, };
        case "ERROR_ADD_CASH_SERVICE_DELIVERY_PASS":
            return { ...state, isAddingCashDeliveryPass: false };


        case "REQUEST_GET_CASH_SERVICE":
            return { ...state, isFetchingCashService: true, };
        case "SUCCESS_GET_CASH_SERVICE":
            return { ...state, cashServiceDataList: action.payload.data, isFetchingCashService: false, };
        case "ERROR_GET_CASH_SERVICE":
            return { ...state, };


        case "REQUEST_GET_CASH_SERVICE_WITH_ID":
            return { ...state, isFetchingCashServiceWithId: true, };
        case "SUCCESS_GET_CASH_SERVICE_WITH_ID":
            return { ...state, cashServiceDataListWithId: action.payload.data, isFetchingCashServiceWithId: false, };
        case "ERROR_GET_CASH_SERVICE_WITH_ID":
            return { ...state, };


        case "REQUEST_ADD_CASH_SERVICE_EXPENSE":
            return { ...state, isAddingCashServiceExpense: true };
        case "SUCCESS_ADD_CASH_SERVICE_EXPENSE":
            return { ...state, cashServiceExpense: action.payload, isAddingCashServiceExpense: false, };
        case "ERROR_ADD_CASH_SERVICE_EXPENSE":
            return { ...state, isAddingCashServiceExpense: false };


        case "REQUEST_SEARCH_SERVICE_WITH_StATUS":
            return { ...state, isFetchingCashServiceWithStatus: true, };
        case "SUCCESS_SEARCH_SERVICE_WITH_StATUS":
            return { ...state, cashServiceDataListWithStatus: action.payload.data, isFetchingCashServiceWithStatus: false, };
        case "ERROR_SEARCH_SERVICE_WITH_StATUS":
            return { ...state, };

        default:
            return state;
    }
};
export default CashServiceReducer;
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../../global/api";
import { UpdateServiceStatusApi, DeleteInsuranceServiceApi, InsuranceServicePDF } from "./insuranceServiceAction";
import { setHeading } from "../../../accounts/account.actions";
// import _ from 'lodash'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import BlockIcon from '@mui/icons-material/Block';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';
const tableRef = React.createRef()


const PaymentList = (props) => {

    useEffect(() => {
        props.setHeading("Inspection");
    }, []);

    return (
        <>
            {props.isFetchingInsuranceService ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            tableRef={tableRef}
                            title="Inspection List"
                            columns={[
                                { title: 'SrNo', field: 'seqNumber' },
                                { title: "Business Location", field: "businessLocationDetails.businessName", editable: 'never' },
                                // { title: "Product Name", field: "serviceNumber", },
                                { title: "Service Type", field: "serviceType" },
                                { title: "Status", field: "status" },
                                { title: "Vehicle Number", field: "vehicleNumber" },
                                { title: "Vehicle Made", field: "vehicleMade" },
                                { title: "Vehicle Model", field: "vehicleModel" },
                                { title: "Customer Name", field: "customerName" },
                                { title: "Customer Number", field: "customerNumber" },
                                { title: "Customer Email", field: "customerEmail" },
                                { title: "Date", field: "date" },
                                { title: "Employee Name", field: "employeeName" },
                                { title: "Remarks", field: "remarks" },
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/insuranceService?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: 'GET',
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: "sticky",
                                    top: 0,
                                    color: "#00BBBB",
                                    fontWeight: "550",
                                    onRowAdd: "none",
                                },
                            }}
                            editable={{
                                // onRowUpdate: (newData, oldData) =>
                                //     new Promise((resolve) => {
                                //         setTimeout(() => {
                                //             resolve();
                                //             // props.updateProduct(newData, oldData);
                                //         }, 600);
                                //     }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.DeleteInsuranceServiceApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                            actions={[
                                {
                                    icon: 'refresh',
                                    tooltip: 'Refresh Data',
                                    isFreeAction: true,
                                    onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                                },
                                (rowData) => ({
                                    disabled: rowData.status === 'Completed' ? false : true,
                                    icon: () => <TaskAltIcon />,
                                    tooltip: "Bill Paid",
                                    onClick: (event, rowData) => {
                                        const status = 'Bill Paid'
                                        props.UpdateServiceStatusApi(status, rowData._id);
                                        tableRef.current && tableRef.current.onQueryChange();
                                    },
                                }),
                                (rowData) => ({
                                    icon: () => <PrintIcon />,
                                    tooltip: "Print Service",
                                    onClick: (event, rowData) => {
                                        props.InsuranceServicePDF(rowData)
                                    },
                                }),
                                (rowData) => ({
                                    icon: () => <VisibilityIcon />,
                                    tooltip: "Preview Service",
                                    // onClick: (event, rowData) => {
                                    //     props.CashServicePDF(rowData)
                                    // },
                                }),
                            ]}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        DeleteInsuranceServiceApi: (productId) => dispatch(DeleteInsuranceServiceApi(productId)),
        InsuranceServicePDF: (data) => dispatch(InsuranceServicePDF(data)),
        // updateProduct: (newData, oldData) => dispatch(updateProduct(newData, oldData)),
        UpdateServiceStatusApi: (newData, oldData) => dispatch(UpdateServiceStatusApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    insuranceServiceDataList: state.InsuranceServiceReducer.insuranceServiceDataList,
    isFetchingInsuranceService: state.InsuranceServiceReducer.isFetchingInsuranceService,
});


export default connect(mapStateToProps, mapDispatchToProps)(PaymentList);
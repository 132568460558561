import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import { addWarehouseApi } from "./BusinessLocationAction";
import { GetCompanyApi } from "../Company/companyAction";
import { setHeading } from "../../../accounts/account.actions";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddWarehouse = (props) => {
  const [businessName, setBusinessName] = useState();
  const [location, setLocation] = useState();
  const [city, setCity] = useState();
  const [zipCode, setZipCode] = useState();
  const [state, setSate] = useState();
  const [contact, setContact] = useState();
  const [description, setDescription] = useState();
  const [manager, setManager] = useState();
  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadWarehouse() {
    props.addWarehouseApi({
      businessName: businessName,
      location: location,
      city: city,
      zipCode: zipCode,
      state: state,
      phone: contact,
      description: description,
      manager: manager,
      companyId: companyId,
    },
      handleVisible,
      handleDangerVisible
    );
    setBusinessName("");
    setLocation("");
    setCity("");
    setZipCode("");
    setSate("");
    setContact("");
    setDescription("");
    setManager("");
    setCompanyName("")
  }

  const handleChangeCompany = (selectedOption) => {
    setCompanyId(selectedOption.value);
    setCompanyName(selectedOption.label);
  };
  const getCompanyList = !_.isEmpty(props.companyDataList) && props.companyDataList.map((x) => {
    let data = { value: x._id, label: x.companyName };
    return data;
  });

  useEffect(() => {
    props.GetCompanyApi();
    props.setHeading("Add Businnes Location");
  }, []);

  return (
    <>
      {props.isAddingWarehouse || props.isFetchingCompany ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col>
                <Form.Group className="mb-1 mt-1" >
                  <Form.Label>
                    <b>Select Company *</b>
                  </Form.Label>
                  <Select
                    value={{ label: companyName }}
                    placeholder="Select Company"
                    onChange={handleChangeCompany}
                    options={getCompanyList}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1 mt-1">
                  <Form.Label>
                    <b>Store Location *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Store Location"
                    onMouseLeave={(e) => setBusinessName(e.target.value)}
                  />
                </Form.Group>
              </Col>

            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1 mt-1">
                  <Form.Label>
                    <b>City </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    onMouseLeave={(e) => setCity(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1 mt-1">
                  <Form.Label>
                    <b>ZipCode </b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="ZipCode"
                    onMouseLeave={(e) => setZipCode(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1 mt-1">
                  <Form.Label>
                    <b>Contact Number </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Contact Number"
                    onMouseLeave={(e) => setContact(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1 mt-1">
                  <Form.Label>
                    <b>Description </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    onMouseLeave={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1 mt-1">
                  <Form.Label>
                    <b>Manager Name </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Manager Name"
                    onMouseLeave={(e) => setManager(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1 mt-1">
                  <Form.Label>
                    <b>Location </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Location"
                    onMouseLeave={(e) => setLocation(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-1 mt-1">
                  <Form.Label>
                    <b>State </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="State"
                    onMouseLeave={(e) => setSate(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              {_.isEmpty(businessName) || _.isEmpty(companyName) ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled>SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadWarehouse}> SAVE</Button>
              )}
            </div>

          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addWarehouseApi: (data, handleVisible, handleDangerVisible) => dispatch(addWarehouseApi(data, handleVisible, handleDangerVisible)),
    GetCompanyApi: () => dispatch(GetCompanyApi()),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  dataAddedWarehouse: state.WarehouseReducer.dataAddedWarehouse,
  dataNotAddedWarehouse: state.WarehouseReducer.dataNotAddedWarehouse,
  isAddingWarehouse: state.WarehouseReducer.isAddingWarehouse,
  companyDataList: state.CompanyReducer.companyDataList,
  isFetchingCompany: state.CompanyReducer.isFetchingCompany,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddWarehouse);

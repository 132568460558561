import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './configureStore';

const store = configureStore()

export const Store = ({ children }) => {
  return (
    <Provider store={store}> {children} </Provider>
  )
}
const initialState = {
    companyList: [],
    companyDataList: [],
    isFetchingCompany: false,
    isAddingCompany: false,
};

const CompanyReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REQUEST_COMPANY_GET_DATA":
            return { ...state, isFetchingCompany: true };
        case "SUCCESS_COMPANY_GET_DATA":
            return { ...state, companyDataList: action.payload.data, isFetchingCompany: false };
        case "ERROR_COMPANY_GET_DATA":
            return { ...state, };


        case "REQUEST_ADD_COMPANY":
            return { ...state, isAddingCompany: true };
        case "SUCCESS_ADD_COMPANY":
            return { ...state, companyList: action.payload, isAddingCompany: false };
        case "ERROR_ADD_COMPANY":
            return { ...state, isAddingCompany: false };


        default:
            return state;
    }
};
export default CompanyReducer;
const initialState = {
    serviceList: [],
    serviceDataList: [],
    isFetchingService: false,
    isAddingService: false,
};

const ServiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REQUEST_SERVICE_GET_DATA":
            return { ...state, isFetchingService: true };
        case "SUCCESS_SERVICE_GET_DATA":
            return { ...state, serviceDataList: action.payload.data, isFetchingService: false };
        case "ERROR_SERVICE_GET_DATA":
            return { ...state, };


        case "REQUEST_ADD_SERVICE":
            return { ...state, isAddingService: true };
        case "SUCCESS_ADD_SERVICE":
            return { ...state, serviceList: action.payload, isAddingService: false };
        case "ERROR_ADD_SERVICE":
            return { ...state, isAddingService: false };


        default:
            return state;
    }
};
export default ServiceReducer;
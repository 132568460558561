import API from "../../../../../global/api";


export const requestAddProduct = () => {
    return {
        type: "REQUEST_ADD_PRODUCT",
    };
};
export const successAddProduct = (data) => {
    return {
        type: "SUCCESS_ADD_PRODUCT",
        payload: data,
    };
};
export const errorAddProduct = () => {
    return {
        type: "ERROR_ADD_PRODUCT",
    };
};
export const AddProductApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddProduct());
        API.post(`/products`, data).then((res) => {
            dispatch(successAddProduct(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddProduct());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestProductData = () => {
    return {
        type: "REQUEST_PRODUCT_GET_DATA",
    };
};
export const successProductGetData = (data) => {
    return {
        type: "SUCCESS_PRODUCT_GET_DATA",
        payload: data,
    };
};
export const errorProductGetData = () => {
    return {
        type: "ERROR_PRODUCT_GET_DATA",
    };
};
export const getProductData = () => {
    return (dispatch) => {
        dispatch(requestProductData());
        API.get(`products`).then((res) => {
            let getData = res.data;
            dispatch(successProductGetData(getData));
        }).catch((error) => {
            dispatch(errorProductGetData());
        })
    };
};


export const UpdateRequestProduct = () => {
    return {
        type: "UPDATE_REQUEST_PRODUCT",
    };
};
export const UpdateSuccessProduct = (data) => {
    return {
        type: "UPDATE_SUCCESS_PRODUCT",
        payload: data,
    };
};
export const UpdateErrorProduct = () => {
    return {
        type: "UPDATE_ERROR_PRODUCT",
    };
};
export const updateProduct = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestProduct());
        API.put(`/products/${oldData._id}`, {
            name: data.name,
            desc: data.desc,
            greenColorTime: data.greenColorTime,
            yellowColorTime: data.yellowColorTime,
            redColorTime: data.redColorTime,
            productBalance: data.productBalance,
            purchasePrice: data.purchasePrice,
            salePrice: data.salePrice,
        }, {
        }).then(res => {
            dispatch(getProductData());
            dispatch(UpdateSuccessProduct([data]))
        }).catch((error) =>
            dispatch(UpdateErrorProduct()));
    }
};


export const DeleteRequestProduct = () => {
    return {
        type: "DELETE_REQUEST_PRODUCT",
    };
};
export const DeleteSuccessProduct = (user_id) => {
    return {
        type: "DELETE_SUCCESS_PRODUCT",
        user_id: user_id,
    };
};
export const DeleteErrorProduct = () => {
    return {
        type: "DELETE_ERROR_PRODUCT",
    };
};
export const deleteProduct = (id) => {
    return dispatch => {
        dispatch(DeleteRequestProduct());
        API.delete(`/products/${id}`).then(res => {
            dispatch(getProductData());
            dispatch(DeleteSuccessProduct(id))
        }).catch((error) => {
            dispatch(DeleteErrorProduct())
        });
    }
};


export const UpdateProductStatusRequest = () => {
    return {
        type: "UPDATE_REQUEST_PRODUCT_STATUS",
    };
};
export const UpdateProductStatusSuccess = (data) => {
    return {
        type: "UPDATE_SUCCESS_PRODUCT_STATUS",
        payload: data,
    };
};
export const UpdateProductStatusError = () => {
    return {
        type: "UPDATE_ERROR_PRODUCT_STATUS",
    };
};
export const updateProductStatusApi = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateProductStatusRequest());
        API.put(`/products/update_product_status/${oldData}`, {
            status: data,
        }, {
        }).then(res => {
            dispatch(getProductData());
            dispatch(UpdateProductStatusSuccess([data]))
        }).catch((error) =>
            dispatch(UpdateProductStatusError()));
    }
};


export const filterItem = (data) => {
    return {
        type: "FILTER_PRODUCT",
        payload: data
    };
};
export const resetReducer = () => {
    return {
        type: "RESET_URL",
    };
};
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, FormGroup, Button } from "react-bootstrap";
import { connect } from "react-redux";
import _ from 'lodash'
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import { getProductData } from "../../MasterControl/Product/productAction";
import { setHeading } from "../../../accounts/account.actions";
import { addPurchaseApi } from "./PurchaseOrderAction";
import { getWarehouseApi } from "../../setting/BusinessLocation/BusinessLocationAction";
import SendIcon from '@mui/icons-material/Send';
import SaveButton from '@mui/material/Button';


const AddPurchaseOrder = (props) => {
    const [businessId, setbusinessId] = useState();
    const [warehouseName, setWarehouseName] = useState();
    const [purchaseDate, setPurchaseDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [purchaserName, setPurchaserName] = useState();
    const [manualPurchaseNumber, setManualPurchaseNumber] = useState();
    const [suplierName, setSuplierName] = useState("");
    const [desc, setDesc] = useState("");
    const [maximumQuantity, setMaximumQuantity] = useState(0);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [inputField, setInputField] = useState([{
        productId: "",
        productName: "",
        quantity: 0,
        stockPrice: 0,
        totalPurchasePrice: 0,
    }]);


    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (msg) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: msg,
            icon: "error",
            button: "Ok",
        });
    };
    function uploadPurchase() {
        props.addPurchaseApi({
            businessId: businessId,
            purchaseDate: purchaseDate,
            purchaserName: purchaserName,
            manualPurchaseNumber: manualPurchaseNumber,
            suplierName: suplierName,
            description: desc,
            data: inputField,
        },
            handleVisible,
            handleDangerVisible
        );
        setbusinessId("")
        setPurchaseDate("")
        setPurchaserName("")
        setManualPurchaseNumber("")
        setSuplierName("");
        setDesc("")
        setInputField([{
            productId: "",
            productName: "",
            quantity: 0,
            stockPrice: 0,
            totalPurchasePrice: 0,
        }]);
    }

    const handleChangeWarehouse = (selectedOption) => {
        setbusinessId(selectedOption.value);
        setWarehouseName(selectedOption.label);
    };
    const getWarehouseList = !_.isEmpty(props.warehouseList.data) && props.warehouseList.data.map((x) => {
        let data = { value: x._id, label: x.businessName };
        return data;
    });

    const productList = props.productDataList.map((x) => {
        let data = { value: x._id, label: x.name };
        return data;
    });


    const handleChange = (e, index, type) => {
        let z = _.find(props.productDataList, (x) => x._id == e.value);
        const { name, value } = e;
        const list = [...inputField];
        if (type == "intname") list[index]["productId"] = e.value;
        if (type == "intname") list[index]["productName"] = e.label;
        if (type == "intname") list[index]["stockPrice"] = Number(z.purchasePrice);
        if (type == "intname") list[index]["productBalance"] = Number(z.productBalance);
        if (type == "intquantity") list[index]["quantity"] = Number(e.target.value);
        if (type == 'intquantity') list[index]['totalPurchasePrice'] = (list[index]['stockPrice'] * list[index]['quantity']);
        setInputField(list);
    };

    const handleAddInput = () => {
        setInputField([
            ...inputField,
            { productId: "", productName: "", quantity: 0, stockPrice: 0, totalPurchasePrice: 0 },
        ]);
    };
    const handleRemoveInput = (index) => {
        const list = [...inputField];
        list.splice(index, 1);
        setInputField(list);
    };

    useEffect(() => {
        props.getProductData();
        props.getWarehouseApi();
        props.setHeading("Add PurchaseOrder ");
    }, []);

    return (
        <>
            {props.isFetchingWarehouseList || props.isAddingPurchase ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isAddingPurchase ? "Saving..." : "Loading..."}
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Select Business Location *</b></Form.Label>
                                    <Select
                                        // value={{ label: restaurantName }}
                                        placeholder="Select Business Location"
                                        onChange={handleChangeWarehouse}
                                        options={getWarehouseList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Date *</b></Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Date"
                                        name="Date"
                                        value={purchaseDate}
                                        onChange={(e) => setPurchaseDate(moment(e.target.value).format("YYYY-MM-DD"))}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Purchaser Name *</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Purchaser Name"
                                        name="Purchaser Name"
                                        onBlur={(e) => setPurchaserName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Manual Purchase Number </b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Manual Purchase Number"
                                        name="Manual Purchase Number"
                                        onBlur={(e) => setManualPurchaseNumber(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Suplier Name *</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Suplier Name"
                                        name="Suplier Name"
                                        value={suplierName}
                                        onChange={(e) => setSuplierName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Description </b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Description"
                                        name="Description"
                                        value={desc}
                                        onChange={(e) => setDesc(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>


                        {inputField.map((item, i) => {
                            console.log(item, "aaaaaaaaaaaaaaaa")
                            return (
                                <React.Fragment key={i}>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="garana">
                                                <Form.Label><b>Select Product *</b> </Form.Label>
                                                <Select
                                                    placeholder="Select Product"
                                                    name="productId"
                                                    onChange={(e) => handleChange(e, i, "intname")}
                                                    options={productList}
                                                    isOptionDisabled={(option) => !_.isEmpty(_.find(inputField, (x) => x.productId == option.value))}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label><b>Available Quantity </b></Form.Label>
                                                <Form.Control
                                                    min={0}
                                                    type="number"
                                                    placeholder="Available Quantity"
                                                    name="productBalance"
                                                    disabled
                                                    value={item.productBalance}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label><b>Price </b></Form.Label>
                                                <Form.Control
                                                    disabled
                                                    min={0}
                                                    type="number"
                                                    placeholder="Price"
                                                    name="stockPrice"
                                                    value={item.stockPrice}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label><b>Add Quantity *</b></Form.Label>
                                                <Form.Control
                                                    min={0}
                                                    type="number"
                                                    placeholder="Add Quantity"
                                                    name="quantity"
                                                    value={item.quantity}
                                                    onChange={(e) => handleChange(e, i, "intquantity")}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                {inputField.length - 1 == i && (
                                                    <Button title="Add PO" onClick={handleAddInput} style={{ float: "right" }}>
                                                        <AddIcon />
                                                    </Button>
                                                )}
                                                {inputField.length !== 1 && (
                                                    <Button title="Remove PO" onClick={() => handleRemoveInput(i)} style={{ float: "right", marginRight: "1%" }}>
                                                        <RemoveIcon />
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </React.Fragment>
                            );
                        })}

                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                            {_.isEmpty(purchaseDate) || _.isEmpty(purchaserName) || _.isEmpty(suplierName) || _.isEmpty(businessId) || inputField[0].productId == "" || inputField[0].quantity == 0 ? (
                                <SaveButton variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </SaveButton>
                            ) : (
                                <SaveButton variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadPurchase}> SAVE </SaveButton>
                            )}
                        </div>

                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getProductData: () => dispatch(getProductData()),
        getWarehouseApi: () => dispatch(getWarehouseApi()),
        setHeading: (data) => dispatch(setHeading(data)),
        addPurchaseApi: (data, handleVisible, handleDangerVisible) => dispatch(addPurchaseApi(data, handleVisible, handleDangerVisible)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingPurchase: state.PurchaseReducer.isAddingPurchase,
    warehouseList: state.WarehouseReducer.warehouseList,
    isFetchingWarehouseList: state.WarehouseReducer.isFetchingWarehouseList,
    productDataList: state.ProductReducer.productDataList,
    isFetchingProduct: state.ProductReducer.isFetchingProduct,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPurchaseOrder);
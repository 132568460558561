import API from "../../../../../global/api";


export const requestAddInsuranceCompany = () => {
    return {
        type: "REQUEST_ADD_INSURANCE_COMPANY",
    };
};
export const successAddInsuranceCompany = (data) => {
    return {
        type: "SUCCESS_ADD_INSURANCE_COMPANY",
        payload: data,
    };
};
export const errorAddInsuranceCompany = () => {
    return {
        type: "ERROR_ADD_INSURANCE_COMPANY",
    };
};
export const addInsuranceCompanyApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddInsuranceCompany());
        API.post(`/insuranceCompany`, data).then((res) => {
            dispatch(successAddInsuranceCompany(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddInsuranceCompany());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestInsuranceCompanyData = () => {
    return {
        type: "REQUEST_INSURANCE_COMPANY_GET_DATA",
    };
};
export const successInsuranceCompanyData = (data) => {
    return {
        type: "SUCCESS_INSURANCE_COMPANY_GET_DATA",
        payload: data,
    };
};
export const errorInsuranceCompanyGetData = () => {
    return {
        type: "ERROR_INSURANCE_COMPANY_GET_DATA",
    };
};
export const GetInsuranceCompanyApi = () => {
    return (dispatch) => {
        dispatch(requestInsuranceCompanyData());
        API.get(`/insuranceCompany?page=0&limit=1000`).then((res) => {
            let getData = res.data;
            dispatch(successInsuranceCompanyData(getData));
        }).catch((error) => {
            dispatch(errorInsuranceCompanyGetData());
        })
    };
};


export const DeleteRequestInsuranceCompany = () => {
    return {
        type: "DELETE_REQUEST_COMPANY",
    };
};
export const DeleteSuccessInsuranceCompany = (user_id) => {
    return {
        type: "DELETE_SUCCESS_COMPANY",
        user_id: user_id,
    };
};
export const DeleteErrorInsuranceCompany = () => {
    return {
        type: "DELETE_ERROR_COMPANY",
    };
};
export const DeleteInsuranceCompanyApi = (id) => {
    return dispatch => {
        dispatch(DeleteRequestInsuranceCompany());
        API.delete(`/insuranceCompany/${id}`).then(res => {
            dispatch(DeleteSuccessInsuranceCompany(id))
        }).catch((error) => {
            dispatch(DeleteErrorInsuranceCompany())
        });
    }
};


export const UpdateRequestInsuranceCompany = () => {
    return {
        type: "UPDATE_REQUEST_COMPANY",
    };
};
export const UpdateSuccessInsuranceCompany = (data) => {
    return {
        type: "UPDATE_SUCCESS_COMPANY",
        payload: data,
    };
};
export const UpdateErrorInsuranceCompany = () => {
    return {
        type: "UPDATE_ERROR_COMPANY",
    };
};
export const UpdateInsuranceCompanyApi = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestInsuranceCompany());
        API.put(`/insuranceCompany/${oldData._id}`, {
            companyName: data.companyName,
            location: data.location,
            description: data.description,
            phone: data.phone,
            email: data.email,
        }, {
        }).then(res => {
            dispatch(UpdateSuccessInsuranceCompany([data]))
        }).catch((error) =>
            dispatch(UpdateErrorInsuranceCompany()));
    }
};
const initialState = {
    insuranceCompanyList: [],
    insuranceCompanyDataList: [],
    isFetchingInsuranceCompany: false,
    isAddingInsuranceCompany: false,
};

const InsuranceCompanyReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REQUEST_INSURANCE_COMPANY_GET_DATA":
            return { ...state, isFetchingInsuranceCompany: true };
        case "SUCCESS_INSURANCE_COMPANY_GET_DATA":
            return { ...state, insuranceCompanyDataList: action.payload.data, isFetchingInsuranceCompany: false };
        case "ERROR_INSURANCE_COMPANY_GET_DATA":
            return { ...state, };


        case "REQUEST_ADD_INSURANCE_COMPANY":
            return { ...state, isAddingInsuranceCompany: true };
        case "SUCCESS_ADD_INSURANCE_COMPANY":
            return { ...state, insuranceCompanyList: action.payload, isAddingInsuranceCompany: false };
        case "ERROR_ADD_INSURANCE_COMPANY":
            return { ...state, isAddingInsuranceCompany: false };


        default:
            return state;
    }
};
export default InsuranceCompanyReducer;
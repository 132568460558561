import React, { useEffect, useState } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../../global/api";
import { DeleteCompanyApi, UpdateCompanyApi } from "./companyAction";
import { setHeading } from "../../../accounts/account.actions";


const ViewResturant = (props) => {
    const permissions = JSON.parse(window.localStorage.getItem("permissions"))
    const Checkpermissions = (permissionsType, permissionsName) => {
        return permissions.some(p => p[`${permissionsType}`] === permissionsName) ? false : true
    }
    useEffect(() => {
        props.setHeading("Company List");
    }, []);
    return (
        <>
            {props.isFetchingCompany ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            title="Company Details"
                            columns={[
                                // { title: 'Restaurant Id', field: 'restaurant_id', editable: 'never' },
                                { title: 'Image', field: 'images', editable: 'never', render: rowData => <img src={`${baseURL}/assets/${rowData.image}`} alt="com" style={{ width: 50, borderRadius: '50%' }} /> },
                                { title: 'Company Name', field: 'companyName' },
                                { title: 'Company Location', field: 'location' },
                                { title: 'Description', field: 'description' },
                                { title: 'NTN Number', field: 'ntn' },
                                { title: 'Email', field: 'email' },
                                { title: 'Phone', field: 'phone' },
                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 10,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [10, 20, 50],
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                },
                            }}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/company?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: 'GET',
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.UpdateCompanyApi(newData, oldData)
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.DeleteCompanyApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        DeleteCompanyApi: (companyId) => dispatch(DeleteCompanyApi(companyId)),
        UpdateCompanyApi: (newData, oldData) => dispatch(UpdateCompanyApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    companyDataList: state.CompanyReducer.companyDataList,
    isFetchingCompany: state.CompanyReducer.isFetchingCompany,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewResturant);
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../../global/api";
import { UpdateUserRes, deleteUserRes } from "./user.actions";
import { isEmpty } from "lodash";
import { setHeading } from "../../../accounts/account.actions";


const ViewUser = (props) => {
  let rollData = [
    {
      roll_id: 1,
      roll_name: "manager",
    },
    {
      roll_id: 2,
      roll_name: "Staff",
    },
    {
      roll_id: 3,
      roll_name: "Admin",
    },
  ];
  let roleArry = ["manager", "staff", "admin"];
  const rollList = !isEmpty(rollData) && rollData.map((x) => {
    let data = { value: x.roll_id, label: x.roll_name };
    return data;
  });
  useEffect(() => {
    props.setHeading("User List")
  }, []);
  return (
    <>
      {props.isFetchingUser ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>

          <div className="main">
            <MaterialTable
              style={{ zIndex: 0 }}
              title="User"
              columns={[
                { title: "Company", field: "companyDetails.companyName", editable: 'never' },
                { title: "Business Locaton", field: "businessLocationDetails.businessName", editable: 'never' },
                { title: "First Name", field: "firstName" },
                { title: "Phone", field: "lastName" },
                { title: "Email", field: "email" },
                { title: "Role", field: "role" },
                { title: "Last Login", field: "lastLogin" },
                // {
                //   title: "Role", field: "role",
                //   editComponent: ({ value, onChange }) => (
                //     <select onChange={(e) => onChange(e.target.value)} style={{ padding: '10px' }}>
                //       <option selected value={value}>
                //         {value}
                //       </option>
                //       {roleArry.map((item) => item !== value && (<option key={item} value={item}>{item}</option>))}
                //     </select>
                //   )
                // },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/users/?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + (query.page);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  }).then((response) => response.json()).then((result) => {
                    resolve({
                      data: result.data,
                      // data:rollData,
                      page: result.page,
                      totalCount: result.total_results,
                    });
                  });
                })
              }
              options={{
                actionsColumnIndex: -1,
                filtering: false,
                exportButton: true,
                exportAllData: true,
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.UpdateUser(newData, oldData);
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteUser(oldData._id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteUser: (id) => dispatch(deleteUserRes(id)),
    UpdateUser: (newData, oldData) => dispatch(UpdateUserRes(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data))
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingUser: state.UserReducer.isFetchingUser,
  userList: state.UserReducer.userList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);

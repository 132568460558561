import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import Swal from "sweetalert";
import { addInsuranceCompanyApi } from "./insuranceCompanyAction";
import { setHeading } from "../../../accounts/account.actions";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddinsuranceCompany = (props) => {
  const [companyName, setCompanyName] = useState("");
  const [description, setdescription] = useState("");
  const [location, setlocation] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


  const handleVisible = (msg) => {
    setAlertVisible(true)
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  }
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  }
  function saveCompany() {
    props.addInsuranceCompanyApi({
      companyName: companyName,
      location: location,
      description: description,
      phone: phone,
      email: email,
    },
      handleVisible,
      handleDangerVisible
    );
    setCompanyName("")
    setdescription("")
    setlocation("")
    setEmail("")
    setPhone("")
  }

  useEffect(() => {
    props.setHeading("Add Insurance Company");
  }, []);

  return (
    <>
      {props.isAddingInsuranceCompany ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Company Name *</b></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Company Name"
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Company Location </b></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Company Location"
                        onChange={(e) => { setlocation(e.target.value) }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Phone *</b></Form.Label>
                      <Form.Control
                        type="Number"
                        placeholder="Phone"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Email *</b></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Description </b></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Description"
                        onChange={(e) => setdescription(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                  {_.isEmpty(companyName) || _.isEmpty(phone) || _.isEmpty(email) ? (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                  ) : (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={saveCompany}> SAVE </Button>
                  )}
                </div>

              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addInsuranceCompanyApi: (data, handleVisible, handleDangerVisible) => dispatch(addInsuranceCompanyApi(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  insuranceCompanyList: state.InsuranceCompanyReducer.insuranceCompanyList,
  isAddingInsuranceCompany: state.InsuranceCompanyReducer.isAddingInsuranceCompany,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddinsuranceCompany);
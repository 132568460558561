import API from "../../../../../global/api";


export const requestAddInspector = () => {
    return {
        type: "REQUEST_ADD_INSPECTOR",
    };
};
export const successAddInspector = (data) => {
    return {
        type: "SUCCESS_ADD_INSPECTOR",
        payload: data,
    };
};
export const errorAddInspector = () => {
    return {
        type: "ERROR_ADD_INSPECTOR",
    };
};
export const AddInspectorApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddInspector());
        API.post(`/inspector`, data).then((res) => {
            dispatch(successAddInspector(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddInspector());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestGetInspector = () => {
    return {
        type: "REQUEST_GET_ALL_INSPECTOR",
    };
};
export const successGetInspector = (data) => {
    return {
        type: "SUCCESS_GET_ALL_INSPECTOR",
        payload: data,
    };
};
export const errorGetInspector = () => {
    return {
        type: "ERROR_GET_ALL_INSPECTOR",
    };
};
export const GetInspectorApi = () => {
    return (dispatch) => {
        dispatch(requestGetInspector());
        API.get(`/inspector`).then((res) => {
            let data1 = res.data;
            dispatch(successGetInspector(data1));
        }).catch((error) => {
            dispatch(errorGetInspector());
        })
    };
};


export const DeleteRequestInspector = () => {
    return {
        type: "DELETE_REQUEST_INSPECTOR",
    };
};
export const DeleteSuccessInspector = (user_id) => {
    return {
        type: "DELETE_SUCCESS_INSPECTOR",
        user_id: user_id,
    };
};
export const DeleteErrorInspector = () => {
    return {
        type: "DELETE_ERROR_INSPECTOR",
    };
};
export const DeleteInspectorApi = (id) => {
    return dispatch => {
        dispatch(DeleteRequestInspector());
        API.delete(`/inspector/${id}`).then(res => {
            dispatch(DeleteSuccessInspector(id))
        }).catch((error) =>
            dispatch(DeleteErrorInspector()));
    }
};


export const UpdateRequestInspector = () => {
    return {
        type: "UPDATE_REQUEST_INSPECTOR",
    };
};
export const UpdateSuccessInspector = (data) => {
    return {
        type: "UPDATE_SUCCESS_INSPECTOR",
        payload: data,
    };
};
export const UpdateErrorInspector = () => {
    return {
        type: "UPDATE_ERROR_INSPECTOR",
    };
};
export const UpdateInspectorApi = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestInspector());
        API.put(`/inspector/${oldData._id}`, {
            inspectorName: data.inspectorName,
            phone: data.phone,
        }, {
        }).then(res => {
            dispatch(UpdateSuccessInspector([data]))
        }).catch((error) =>
            dispatch(UpdateErrorInspector()));
    }
};
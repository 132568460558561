import React, { useState } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, toLower } from "lodash";
import Select from "react-select";
import { addUserRes } from "./user.actions";
import { GetCompanyApi } from "../Company/companyAction";
import { getWarehouseWithResApi } from "../BusinessLocation/BusinessLocationAction";
import { setHeading } from "../../../accounts/account.actions";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddUser = (props) => {
  const [customerName, setCustomerName] = useState("");
  const [userName, setUserName] = useState("");
  const [passwordUser, setPasswordUser] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [roleName, setRoleName] = useState("");
  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState();
  const [warehouseId, setWarehouseId] = useState();
  const [warehouseName, setWarehouseName] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [submit, setSubmit] = useState(false);


  function uploadUser() {
    props.addUser({
      firstName: customerName,
      phone: phone,
      email: userName,
      password: passwordUser,
      role: toLower(roleName),
      companyId: companyId,
      businessId: warehouseId,
    },
      handleVisible,
      handleDangerVisible
    );
  }
  const handleVisible = (msg) => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  let rollData = [
    {
      roll_id: 1,
      roll_name: "Branch",
    },
    {
      roll_id: 2,
      roll_name: "Admin",
    }
  ];
  const handleChangeRoll = (selectedOption) => {
    setRoleName(selectedOption.label);
  };
  const rollList = !isEmpty(rollData) && rollData.map((x) => {
    let data = { value: x.roll_id, label: x.roll_name };
    return data;
  });
console.log(props.companyDataList, "ccccccccccccccccccc")
  const handleChangeCompany = (selectedOption) => {
    setCompanyId(selectedOption.value);
    setCompanyName(selectedOption.label);
    props.getWarehouseWithResApi(selectedOption.value);
  };
  const getCompanyList = !isEmpty(props.companyDataList) && props.companyDataList.map((x) => {
    let data = { value: x._id, label: x.companyName };
    return data;
  });

  const handleChangeWarehouse = (selectedOption) => {
    setWarehouseId(selectedOption.value);
    setWarehouseName(selectedOption.label);
  };
  const getWarehouseList = !isEmpty(props.warehouseListWithResId.data) && props.warehouseListWithResId.data.map((x) => {
    let data = { value: x._id, label: x.businessName };
    return data;
  });

  const clearStates = () => {
    setPhone("");
    setPasswordUser("");
    setUserName("");
    setCustomerName("");
    setCompanyName("")
    setWarehouseName("")
    setRoleName("")
    setSubmit(false);
  };


  useEffect(() => {
    props.setHeading("Add User")
    props.GetCompanyApi()
  }, []);

  return (
    <>
      {props.isFetchingCompany || props.isAddingUser ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Company *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.companyDataList)}
                    placeholder="Select Company"
                    onChange={handleChangeCompany}
                    options={getCompanyList}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Business Location *</b>
                  </Form.Label>
                  <Select
                    placeholder="Select Business Location"
                    isDisabled={isEmpty(props.warehouseListWithResId) || isEmpty(companyId)}
                    onChange={handleChangeWarehouse}
                    options={getWarehouseList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Name </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Phone</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Email *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Password *</b>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    minLength={8}
                    // maxLength={13}
                    placeholder="Password"
                    value={passwordUser}
                    onChange={(e) => setPasswordUser(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Role *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(rollData)}
                    placeholder="Select Role"
                    onChange={handleChangeRoll}
                    options={rollList}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              {isEmpty(userName) || isEmpty(passwordUser) || isEmpty(companyId) || isEmpty(roleName) || isEmpty(warehouseId) ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadUser}> SAVE </Button>
              )}
            </div>

          </Form>
        </Container>)}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (data, handleVisible, handleDangerVisible) => dispatch(addUserRes(data, handleVisible, handleDangerVisible)),
    GetCompanyApi: () => dispatch(GetCompanyApi()),
    setHeading: (data) => dispatch(setHeading(data)),
    getWarehouseWithResApi: (id) => dispatch(getWarehouseWithResApi(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  addUser: state.UserReducer.addUser,
  isAddingUser: state.UserReducer.isAddingUser,
  companyDataList: state.CompanyReducer.companyDataList,
  isFetchingCompany: state.CompanyReducer.isFetchingCompany,
  warehouseListWithResId: state.WarehouseReducer.warehouseListWithResId,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);

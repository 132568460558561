import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import Select from "react-select";
import { GetIncServiceWithStatusApi } from "./insuranceServiceAction";
import { setHeading } from "../../../accounts/account.actions";
import { Link, Navigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';


const IncuranceServiceReport = (props) => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [status, setStatusValue] = useState("");


    const handleChangeStatus = (selectedOption) => {
        setStatusValue(selectedOption.value);
    };
    const getStatusList = [
        { value: "Work In Process", label: "Work In Process" },
        { value: "Inspection Approved", label: "Inspection Approved" },
        { value: "Completed", label: "Completed" },
    ];

    useEffect(() => {
        props.setHeading("Insurance Service Report");
    }, []);

    function searchData() {
        props.GetIncServiceWithStatusApi({
            startDate: !_.isEmpty(startDate) ? startDate : "",
            endDate: !_.isEmpty(endDate) ? endDate : "",
            status: status,
        },
            startDate,
            endDate
        );
        return <Navigate to="/mainService/Insurance/IncServiceReportData" />;
    }

    return (
        <>
            <Container fluid>
                <div className="main">
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label><b>Start Date</b></Form.Label>
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label><b>End Date</b></Form.Label>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label><b>Select Status *</b></Form.Label>
                                <Select
                                    placeholder="Select Status"
                                    onChange={handleChangeStatus}
                                    options={getStatusList}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <div style={{ marginTop: '40px', textAlign: 'center' }}>
                        {_.isEmpty(status) ? (
                            <Button variant="contained" endIcon={<SearchIcon />} size="large" disabled> SEARCH </Button>
                        ) : (
                            <Link to={{ pathname: "/mainService/Insurance/IncServiceReportData", state: { start: startDate, end: endDate } }}>
                                <Button variant="contained" endIcon={<SearchIcon />} size="large" onClick={(e) => searchData()}> SEARCH </Button>
                            </Link>
                        )}
                    </div>

                </div>
            </Container>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetIncServiceWithStatusApi: (data, startDate, endDate) => dispatch(GetIncServiceWithStatusApi(data, startDate, endDate)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
}
const mapStateToProps = (state, ownProps) => ({
    incServiceWithStatus: state.InsuranceServiceReducer.incServiceWithStatus,
    isFetchingIncServiceWithStatus: state.InsuranceServiceReducer.isFetchingIncServiceWithStatus,
})

export default connect(mapStateToProps, mapDispatchToProps)(IncuranceServiceReport);

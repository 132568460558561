import API from "../../../../../global/api";


export const requestGetBackUp = () => {
    return {
        type: "REQUEST_GET_BACK_UP",
    };
};
export const successGetBackUp = (data) => {
    return {
        type: "SUCCESS_GET_BACK_UP",
        payload: data,
    };
};
export const errorGetBackUp = () => {
    return {
        type: "ERROR_GET_BACK_UP",
    };
}
export const getBackUp = (handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestGetBackUp());
        API.get(`/users/backup`).then((res) => {
            let getData = res.data;
            dispatch(successGetBackUp(getData));
            handleVisible(getData.message)
        }).catch((error) => {
            dispatch(errorGetBackUp());
            handleDangerVisible()
        })
    };
};
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { setHeading } from "../../../accounts/account.actions";
import ImageUploader from "react-images-upload";
import { uploadImageApi } from "../../setting/uploadImage/uploadImageAction";
import { GetCashServiceApi, AddCashDeliveryPassApi, resetReducer } from "./cashServiceAction";


const AddGatePass = (props) => {
    const [ownerName, setOwnerName] = useState();
    const [residenceID, setResidenceID] = useState();
    const [reciverName, setReceiverName] = useState();
    const [contactNumber, setContactNumber] = useState();
    const [remarks, setRemarks] = useState();
    const [deliverDate, setDeliverDate] = useState();
    const [insuranceServiceId, setinsuranceServiceId] = useState();
    const [mainServiceName, setMainServiceName] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };
    function DeliveryPass() {
        props.AddCashDeliveryPassApi({
            insuranceServiceId: insuranceServiceId,
            ownerName: ownerName,
            residenceID: residenceID,
            reciverName: reciverName,
            contactNumber: contactNumber,
            remarks: remarks,
            deliverDate: deliverDate,
            feeds: props.UploadImageURL,
        },
            handleVisible,
            handleDangerVisible
        );
        setOwnerName("")
        setResidenceID("")
        setReceiverName("")
        setContactNumber("")
        setRemarks("")
        setDeliverDate("")
        setinsuranceServiceId("")
        props.resetReducer()
    }

    const onDrop = (pictureFiles) => {
        props.uploadImageApi({
            image: pictureFiles[0],
        });
    };

    const handleChangeMainService = (selectedOption) => {
        setinsuranceServiceId(selectedOption.value);
        setMainServiceName(selectedOption.label);
    };
    const filterService = _.filter(props.cashServiceDataList, { status: 'Bill Paid' })
    const getMainServiceList = !_.isEmpty(filterService) && filterService.map((x) => {
        let data = { value: x._id, label: `${x.vehicleNumber} / ${x.vehicleMade} / ${x.customerName}` };
        return data;
    });

    useEffect(() => {
        props.setHeading("Add Delivery Pass");
        props.GetCashServiceApi()
    }, []);

    return (<>
        {props.isAddingCashDeliveryPass || props.isFetchingCashService ? (
            <div className="loader-div">
                <Spinner
                    variant="primary"
                    animation="grow"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                />
            </div>
        ) : (
            <Container fluid>
                <div className="main">
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="garana">
                                <Form.Label> <b>Select Service *</b> </Form.Label>
                                <Select
                                    isDisabled={_.isEmpty(filterService)}
                                    placeholder="Select Service"
                                    onChange={handleChangeMainService}
                                    options={getMainServiceList}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="garana">
                                <Form.Label><b>Owner Name *</b></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Owner Name"
                                    onChange={(e) => setOwnerName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="garana">
                                <Form.Label> <b>Residence ID *</b> </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Residence ID"
                                    onChange={(e) => setResidenceID(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="garana">
                                <Form.Label> <b>Delivery Date </b> </Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Delivery Date"
                                    onChange={(e) => setDeliverDate(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="garana">
                                <Form.Label><b>Contact Number *</b></Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Contact Number"
                                    onChange={(e) => setContactNumber(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="garana">
                                <Form.Label><b>Receiver Name *</b></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Receiver Name"
                                    onChange={(e) => setReceiverName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="garana">
                                <Form.Label><b>Remarks </b></Form.Label>
                                <Form.Control
                                    type="text"
                                    as='textarea'
                                    rows={3}
                                    placeholder="Remarks"
                                    onChange={(e) => setRemarks(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label> <b style={{ fontSize: "large" }}> Upload Images </b> </Form.Label>
                                <ImageUploader
                                    withIcon={false}
                                    buttonText="Upload Images"
                                    onChange={onDrop}
                                    // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                                    imgExtension={[".jpg", ".jpeg", ".png"]}
                                    maxFileSize={999999999999999999999}
                                    singleImage={false}
                                    withPreview={true}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                        {_.isEmpty(mainServiceName) || _.isEmpty(ownerName) || _.isEmpty(residenceID) || _.isEmpty(reciverName) || _.isEmpty(contactNumber) ? (
                            <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                        ) : (
                            <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={DeliveryPass}> SAVE </Button>
                        )}
                    </div>

                </div>
            </Container>
        )}
    </>)

}

const mapDispatchToProps = (dispatch) => {
    return {
        AddCashDeliveryPassApi: (data, handleVisible, handleDangerVisible) => dispatch(AddCashDeliveryPassApi(data, handleVisible, handleDangerVisible)),
        GetCashServiceApi: () => dispatch(GetCashServiceApi()),
        uploadImageApi: (data) => dispatch(uploadImageApi(data)),
        setHeading: (data) => dispatch(setHeading(data)),
        resetReducer: () => dispatch(resetReducer()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingCashDeliveryPass: state.CashServiceReducer.isAddingCashDeliveryPass,
    cashServiceDataList: state.CashServiceReducer.cashServiceDataList,
    isFetchingCashService: state.CashServiceReducer.isFetchingCashService,
    UploadImageURL: state.UploadImageReducer.UploadImageURL,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGatePass);
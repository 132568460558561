import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import Swal from "sweetalert";
import { addCompanyApi } from "./companyAction";
import { setHeading } from "../../../accounts/account.actions";
import ImageUploader from "react-images-upload";
import { uploadImageApi } from "../uploadImage/uploadImageAction";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddCompany = (props) => {
  const [restaurant, setRestaurant] = useState("");
  const [restaurantDesc, setRestaurantDesc] = useState("");
  const [ntnNumber, setNTNNumber] = useState(0);
  const [restaurantLocation, setRestaurantLocation] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");


  const handleVisible = (msg) => {
    setAlertVisible(true)
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  }
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  }
  const onDrop = (pictureFiles, pictureDataURLs) => {
    props.uploadImageApi({
      image: pictureFiles[0],
    })
  };
  function saveCompany() {
    props.addCompanyApi({
      companyName: restaurant,
      location: restaurantLocation,
      description: restaurantDesc,
      image: props.UploadImageURL.file,
      ntn: ntnNumber,
      phone: phone,
      email: email,
    },
      handleVisible,
      handleDangerVisible
    );
    setRestaurantDesc("");
  }

  useEffect(() => {
    props.setHeading("Add Company");
  }, []);

  return (
    <>
      {props.isAddingCompany ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Company Name *</b></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Company Name"
                        onChange={(e) => setRestaurant(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Company Location *</b></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Company Location"
                        onChange={(e) => { setRestaurantLocation(e.target.value) }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Phone *</b></Form.Label>
                      <Form.Control
                        type="Number"
                        placeholder="Phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Email *</b></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        // max={100}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Description </b></Form.Label>
                      <Form.Control
                        type="text"
                        value={restaurantDesc}
                        placeholder="Description"
                        onChange={(e) => setRestaurantDesc(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>VAT Number </b></Form.Label>
                      <Form.Control
                        type="Number"
                        value={ntnNumber}
                        placeholder="VAT Number"
                        onChange={(e) => setNTNNumber(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label><b style={{ fontSize: "large" }}> Upload Company Logo *</b> </Form.Label>
                      <ImageUploader
                        withIcon={false}
                        buttonText="Upload Company Logo"
                        onChange={onDrop}
                        // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                        imgExtension={[".jpg", ".jpeg", ".png"]}
                        maxFileSize={999999999999999999999}
                        singleImage={true}
                        withPreview={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                  {isEmpty(restaurant) || isEmpty(restaurantLocation) || isEmpty(props.UploadImageURL.file) ? (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                  ) : (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={saveCompany}> SAVE </Button>
                  )}
                </div>

              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCompanyApi: (data, handleVisible, handleDangerVisible) => dispatch(addCompanyApi(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
    uploadImageApi: (data) => dispatch(uploadImageApi(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  companyList: state.CompanyReducer.companyList,
  isAddingCompany: state.CompanyReducer.isAddingCompany,
  UploadImageURL: state.UploadImageReducer.UploadImageURL,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCompany);
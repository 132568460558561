import React, { useEffect } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../../global/api";
import { DeleteServiceApi, UpdateServiceApi } from "./serviceAction";
import { setHeading } from "../../../accounts/account.actions";


const ViewResturant = (props) => {
    const permissions = JSON.parse(window.localStorage.getItem("permissions"))
    const Checkpermissions = (permissionsType, permissionsName) => {
        return permissions.some(p => p[`${permissionsType}`] === permissionsName) ? false : true
    }
    useEffect(() => {
        props.setHeading("Service List");
    }, []);
    return (
        <>
            {props.isFetchingService ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            title="Service Details"
                            columns={[
                                { title: 'Service Name', field: 'serviceName' },
                                { title: 'Description', field: 'description' },
                                { title: 'Price', field: 'price' },
                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 10,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [10, 20, 50],
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                },
                            }}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/service?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: 'GET',
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.UpdateServiceApi(newData, oldData)
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.DeleteServiceApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        DeleteServiceApi: (companyId) => dispatch(DeleteServiceApi(companyId)),
        UpdateServiceApi: (newData, oldData) => dispatch(UpdateServiceApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    serviceDataList: state.ServiceReducer.serviceDataList,
    isFetchingService: state.ServiceReducer.isFetchingService,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewResturant);
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import { AddInspectorApi } from "./inspectorAction";
import { GetInsuranceCompanyApi } from "../InsuranceCompany/insuranceCompanyAction";
import { setHeading } from "../../../accounts/account.actions";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddInspector = (props) => {
    const [insuranceCompanyId, setInsuranceCompanyId] = useState();
    const [insuranceCompanyName, setInsuranceCompanyName] = useState();
    const [inspectorName, setInspectorName] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };

    function SaveInspector() {
        props.AddInspectorApi({
            insuranceCompanyId: insuranceCompanyId,
            inspectorName: inspectorName,
            phone: phone,
            email: email,
        },
            handleVisible,
            handleDangerVisible
        );
        setInsuranceCompanyId("")
        setInspectorName("")
        setPhone("")
        setEmail("")
    }

    const handleChangeInsuranceCompany = (selectedOption) => {
        setInsuranceCompanyId(selectedOption.value);
        setInsuranceCompanyName(selectedOption.label);
    };
    const getInsuranceCompanyList = !_.isEmpty(props.insuranceCompanyDataList) && props.insuranceCompanyDataList.map((x) => {
        let data = { value: x._id, label: x.companyName };
        return data;
    });

    useEffect(() => {
        props.GetInsuranceCompanyApi();
        props.setHeading("Add Inspector");
    }, []);

    return (
        <>
            {props.isAddingInspector || props.isFetchingInsuranceCompany ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Row>
                            <Col>
                                <Form.Group className="mb-1 mt-1" >
                                    <Form.Label><b>Select Insurance Company *</b></Form.Label>
                                    <Select
                                        placeholder="Select Insurance Company"
                                        onChange={handleChangeInsuranceCompany}
                                        options={getInsuranceCompanyList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1 mt-1">
                                    <Form.Label><b>Inspector Name *</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Inspector Name"
                                        onMouseLeave={(e) => setInspectorName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1 mt-1">
                                    <Form.Label><b>Email *</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Email"
                                        onMouseLeave={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1 mt-1">
                                    <Form.Label><b>Phone *</b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Phone"
                                        onMouseLeave={(e) => setPhone(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div style={{ marginTop: '20px', textAlign: 'center' }}>
                            {_.isEmpty(insuranceCompanyName) || _.isEmpty(inspectorName) || _.isEmpty(email) || _.isEmpty(phone) ? (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                            ) : (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveInspector}> SAVE </Button>
                            )}
                        </div>

                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        AddInspectorApi: (data, handleVisible, handleDangerVisible) => dispatch(AddInspectorApi(data, handleVisible, handleDangerVisible)),
        GetInsuranceCompanyApi: () => dispatch(GetInsuranceCompanyApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    insuranceCompanyDataList: state.InsuranceCompanyReducer.insuranceCompanyDataList,
    isFetchingInsuranceCompany: state.InsuranceCompanyReducer.isFetchingInsuranceCompany,
    isAddingInspector: state.InspectorReducer.isAddingInspector,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddInspector);
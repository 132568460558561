import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../../global/api";
import { UpdateServiceStatusApi, DeleteInsuranceServiceApi, InsuranceServicePDF } from "./insuranceServiceAction";
import { setHeading } from "../../../accounts/account.actions";
import _ from 'lodash'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
const tableRef = React.createRef()


const CompleteList = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [seletcedData, setSelectedData] = React.useState();
    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    useEffect(() => {
        props.setHeading("Inspection");
    }, []);

    return (
        <>
            {props.isFetchingInsuranceService ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            tableRef={tableRef}
                            title="Inspection List"
                            columns={[
                                { title: 'Sr No', field: 'seqNumber' },
                                { title: "Business Location", field: "businessLocationDetails.businessName", editable: 'never' },
                                // { title: "Product Name", field: "serviceNumber", },
                                { title: "Service Type", field: "serviceType" },
                                { title: "Status", field: "status" },
                                { title: "Vehicle Number", field: "vehicleNumber" },
                                { title: "Vehicle Made", field: "vehicleMade" },
                                { title: "Vehicle Model", field: "vehicleModel" },
                                { title: "Customer Name", field: "customerName" },
                                { title: "Customer Number", field: "customerNumber" },
                                { title: "Customer Email", field: "customerEmail" },
                                { title: "Date", field: "date" },
                                { title: "Employee Name", field: "employeeName" },
                                { title: "Remarks", field: "remarks" },
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/insuranceService?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: 'GET',
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: "sticky",
                                    top: 0,
                                    color: "#00BBBB",
                                    fontWeight: "550",
                                    onRowAdd: "none",
                                },
                            }}
                            editable={{
                                // onRowUpdate: (newData, oldData) =>
                                //     new Promise((resolve) => {
                                //         setTimeout(() => {
                                //             resolve();
                                //             // props.updateProduct(newData, oldData);
                                //         }, 600);
                                //     }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.DeleteInsuranceServiceApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                            actions={[
                                {
                                    icon: 'refresh',
                                    tooltip: 'Refresh Data',
                                    isFreeAction: true,
                                    onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                                },
                                (rowData) => ({
                                    disabled: rowData.status === 'Inspection Approved' ? false : true,
                                    icon: () => <TaskAltIcon />,
                                    tooltip: "Completed",
                                    onClick: (event, rowData) => {
                                        const status = 'Completed'
                                        props.UpdateServiceStatusApi(status, rowData._id);
                                        tableRef.current && tableRef.current.onQueryChange();
                                    },
                                }),
                                (rowData) => ({
                                    icon: () => <PrintIcon />,
                                    tooltip: "Print Service",
                                    onClick: (event, rowData) => {
                                        props.InsuranceServicePDF(rowData)
                                    },
                                }),
                                (rowData) => ({
                                    icon: () => <VisibilityIcon />,
                                    tooltip: "Preview Service",
                                    onClick: (event, rowData) => {
                                        setSelectedData(rowData)
                                        handleOpen()
                                    },
                                }),
                            ]}
                        />
                    </div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div style={{ width: '100%', backgroundColor: '#aed6ae', padding: '20px' }}>
                                <div style={{ width: '100%', backgroundColor: '#aed6ae', padding: '15px', borderRadius: '25px' }}>
                                    <Row>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Vehicle Number: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(seletcedData) ? '' : seletcedData.vehicleNumber}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Vehicle Made: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(seletcedData) ? '' : seletcedData.vehicleMade}</span> </b></Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Vehicle Model: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(seletcedData) ? '' : seletcedData.vehicleModel}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Name: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(seletcedData) ? '' : seletcedData.customerName}</span> </b></Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Number: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(seletcedData) ? '' : seletcedData.customerNumber}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Service Type: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(seletcedData) ? '' : seletcedData.customerEmail}</span> </b></Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Email: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(seletcedData) ? '' : seletcedData.serviceType}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Total Expense: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(seletcedData) ? 0 : seletcedData.totalExpense}</span> </b></Col>
                                    </Row>
                                </div>

                                <Row style={{ marginTop: '30px' }}>
                                    <Col xl="6" lg="6" md="6" sm="6" style={{ borderRightStyle: "solid" }} >
                                        <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> Product </Col>
                                        <Row>
                                            <Col xl="1" lg="1" md="1" sm="1"><h6><b>Sr</b></h6></Col>
                                            <Col xl="5" lg="5" md="5" sm="5"><h6><b>Service</b></h6></Col>
                                            <Col xl="3" lg="3" md="3" sm="3"><h6><b>Quantity</b></h6></Col>
                                            <Col xl="3" lg="3" md="3" sm="3"><h6><b>Price</b></h6></Col>
                                        </Row>
                                        {_.isUndefined(seletcedData) ? '' : seletcedData.productExpenseData.map((item, i) => {
                                            return (
                                                <React.Fragment>
                                                    <Row>
                                                        <Col xl="1" lg="1" md="1" sm="1">{i + 1}</Col>
                                                        <Col xl="5" lg="5" md="5" sm="5">{item.productName}</Col>
                                                        <Col xl="3" lg="3" md="3" sm="3">{item.quantity}</Col>
                                                        <Col xl="3" lg="3" md="3" sm="3">{item.salePrice}</Col>
                                                    </Row>
                                                </React.Fragment>
                                            )
                                        })}
                                    </Col>

                                    <Col xl="6" lg="6" md="6" sm="6" >
                                        <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> Service </Col>
                                        <Row style={{ width: '100%' }}>
                                            <Row>
                                                <Col xl="1" lg="1" md="1" sm="1"><h6><b>Sr</b></h6></Col>
                                                <Col xl="5" lg="5" md="5" sm="5"><h6><b>Service</b></h6></Col>
                                                <Col xl="3" lg="3" md="3" sm="3"><h6><b>Quantity</b></h6></Col>
                                                <Col xl="3" lg="3" md="3" sm="3"><h6><b>Price</b></h6></Col>
                                            </Row>
                                        </Row>
                                        {_.isUndefined(seletcedData) ? '' : seletcedData.serviceExpenseData.map((item, i) => {
                                            return (
                                                <React.Fragment>
                                                    <Row>
                                                        <Col xl="1" lg="1" md="1" sm="1">{i + 1}</Col>
                                                        <Col xl="5" lg="5" md="5" sm="5">{item.productName}</Col>
                                                        <Col xl="3" lg="3" md="3" sm="3">{item.quantity}</Col>
                                                        <Col xl="3" lg="3" md="3" sm="3">{item.salePrice}</Col>
                                                    </Row>
                                                </React.Fragment>
                                            )
                                        })}
                                    </Col>
                                </Row>
                            </div>
                        </Box>
                    </Modal>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        DeleteInsuranceServiceApi: (productId) => dispatch(DeleteInsuranceServiceApi(productId)),
        InsuranceServicePDF: (data) => dispatch(InsuranceServicePDF(data)),
        // updateProduct: (newData, oldData) => dispatch(updateProduct(newData, oldData)),
        UpdateServiceStatusApi: (newData, oldData) => dispatch(UpdateServiceStatusApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    insuranceServiceDataList: state.InsuranceServiceReducer.insuranceServiceDataList,
    isFetchingInsuranceService: state.InsuranceServiceReducer.isFetchingInsuranceService,
});


export default connect(mapStateToProps, mapDispatchToProps)(CompleteList);
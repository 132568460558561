import API from "../../../../../global/api";
import _ from "lodash";
import logo from "../../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";


export const requestSearchProductLedger = () => {
    return {
        type: "REQUEST_SEARCH_PRODUCT",
    };
};
export const successSearchProductLedger = (data) => {
    return {
        type: "SUCCESS_SEARCH_PRODUCT",
        payload: data,
    };
};
export const errorSearchProductLedger = () => {
    return {
        type: "ERROR_SEARCH_PRODUCT",
    };
};
export const SearchStoreLedgerApi = (data, startDate, endDate) => {
    return (dispatch) => {
        dispatch(requestSearchProductLedger());
        API.post(`/products/search_ledger`, data).then((res) => {
            let getData = res.data;
            dispatch(successSearchProductLedger(getData));
        }).catch((error) => {
            dispatch(errorSearchProductLedger());
        });
    };
};





export const requestSearchInventoryLedgerReport = () => {
    return {
        type: "REQUEST_SEARCH_INVENTORY_REPORT",
    };
};
export const successSearchInventoryLedgerReport = (data) => {
    return {
        type: "SUCCESS_SEARCH_INVENTORY_REPORT",
        payload: data,
    };
};
export const errorSearchInventoryLedgerReport = () => {
    return {
        type: "ERROR_SEARCH_INVENTORY_REPORT",
    };
};
export const searchInventoryLedgerReport = (data, startDate, endDate) => {
    return (dispatch) => {
        console.log(data, "data");
        dispatch(requestSearchInventoryLedgerReport());
        API.post(`/products/inventory_ledger`, data)
            .then((res) => {
                let getData = res.data;
                console.log(getData, "data11");
                const unit = "pt";
                const size = "A4"; // Use A1, A2, A3 or A4
                const orientation = "portrait"; // portrait or landscape

                const marginLeft = 40;
                const doc = new jsPDF(orientation, unit, size);

                doc.setFontSize(11);
                const title = "Inventorys Ledger";
                const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                const Dates = !_.isEmpty(startDate) || !_.isEmpty(endDate) ? "Form: " + " " + startDate + "  to:  " + endDate : "Form: -  To: - ";
                // const accountNo = "Inventory #:  " + getData.data.key;
                // const accountTitle = "Inventory Title:  " + getData.data[1].accountTittle;
                const TotalCredit = "Total Add Quantity" + getData.addQuantity;
                const TotalDebit = "Closing Balance: " + getData.closing_balance;
                // const Rarray = getData.data.slice(-1);
                // const TotalBalance = "Closing Balance: " + Rarray[0].accBalance.toLocaleString();

                const headers = [[
                    "DATE",
                    "Add Quantity",
                    "Less Quantity",
                    "Total Quantity",
                    "OPENING BALANCE",
                ]];
                const data = getData.data.map((x) => [
                    x.transactionDate,
                    x.addQuantity,
                    x.lessQuantity,
                    x.closingBalance,
                    x.openingBalance,
                ]);
                let content = {
                    startY: 210,
                    startX: 10,
                    head: headers,
                    body: data,
                };
                doc.addImage(logo, "PNG", 40, 15, 100, 100);
                doc.setFontSize(20);
                doc.setFont(undefined, "bold");
                doc.text(companyName, 150, 50, 0, 20);
                doc.setFontSize(13);
                doc.setFont("TimesNewRoman", "Normal");
                doc.text(companyAddress, 150, 70, 0, 20);
                doc.text(companyNtn, 150, 90, 0, 20);
                doc.setFontSize(18);
                doc.setFont("TimesNewRoman", "bold");
                doc.text(title, 215, 120);
                doc.setFontSize(13);
                doc.setFont("TimesNewRoman", "Normal");
                doc.text(Dates, 200, 140, 0, 20);
                //   doc.text(accountTitle, marginLeft, 160, 0, 20);
                doc.text(TotalCredit, marginLeft, 160);
                doc.text(TotalDebit, marginLeft, 180);
                //  doc.text(TotalBalance, marginLeft, 225);
                doc.autoTable(content);
                doc.setProperties({ title: `Inventory Ledger Report ${moment(new Date()).format("YYYY-MM-DD")}` });
                doc.output("dataurlnewwindow");
                doc.save(`Inventory Ledger Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`);
                dispatch(successSearchInventoryLedgerReport(getData));
            }).catch((error) => {
                dispatch(errorSearchInventoryLedgerReport());
            });
    };
};
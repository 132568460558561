const initialState = {
    inspectorList: [],
    inspectorDataList: [],
    isFetchingInspector: false,
    isAddingInspector: false,
};

const InspectorReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_INSPECTOR":
            return { ...state, isAddingInspector: true };
        case "SUCCESS_ADD_INSPECTOR":
            return { ...state, inspectorList: action.payload, isAddingInspector: false };
        case "ERROR_ADD_INSPECTOR":
            return { ...state, isAddingInspector: false };


        case "REQUEST_GET_ALL_INSPECTOR":
            return { ...state, isFetchingInspector: true };
        case "SUCCESS_GET_ALL_INSPECTOR":
            return { ...state, inspectorDataList: action.payload.data, isFetchingInspector: false };
        case "ERROR_GET_ALL_INSPECTOR":
            return { ...state, };


        default:
            return state;
    }
};
export default InspectorReducer;
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from 'lodash'
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Select from "react-select";
import ImageUploader from "react-images-upload";
import { AddInsuranceServiceApi, resetReducer } from "./insuranceServiceAction";
import { GetInsuranceCompanyApi } from "../../MasterControl/InsuranceCompany/insuranceCompanyAction";
import { uploadImageApi } from "../../setting/uploadImage/uploadImageAction";
import { getWarehouseApi } from "../../setting/BusinessLocation/BusinessLocationAction";
import { setHeading } from "../../../accounts/account.actions";
import moment from "moment";



const AddInsuranceService = (props) => {
  const [businessId, setbusinessId] = useState();
  const [warehouseName, setWarehouseName] = useState();
  const [insuranceCompanyId, setIncCompanyId] = useState();
  const [insuranceCompanyName, setIncCompanyName] = useState();
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleMade, setVehicleMade] = useState();
  const [vehicleModel, setVehicleModel] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [employeeName, setEmployeeName] = useState();
  const [remarks, setRemarks] = useState()
  const [date, setDate] = useState()
  const [typeValue, setTypeVale] = useState();
  const [customerIDNumber, setCustomerIDNumber] = useState();
  const [typeName, setTypeName] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  function SaveInsuranceService() {
    props.AddInsuranceServiceApi({
      businessId: businessId,
      insuranceCompanyId: insuranceCompanyId,
      images: props.UploadImageURL,
      vehicleNumber: vehicleNumber,
      vehicleMade: vehicleMade,
      vehicleModel: vehicleModel,
      customerName: customerName,
      customerNumber: customerNumber,
      customerEmail: customerEmail,
      employeeName: employeeName,
      remarks: remarks,
      date: date,
      typeValue: typeValue,
      customerIDNumber: customerIDNumber,
      serviceType: 'Insurance Service'
    },
      handleVisible,
      handleDangerVisible
    );
    setbusinessId("")
    setIncCompanyId("")
    setVehicleNumber("")
    setVehicleMade("")
    setVehicleModel("")
    setCustomerName("")
    setCustomerNumber("")
    setCustomerEmail("")
    setEmployeeName("")
    setRemarks("")
    setDate("")
    props.resetReducer()
  }

  const onDrop = (pictureFiles) => {
    props.uploadImageApi({
      image: pictureFiles[0],
    });
  };

  const handleChangeWarehouse = (selectedOption) => {
    setbusinessId(selectedOption.value);
    setWarehouseName(selectedOption.label);
  };
  const getWarehouseList = !_.isEmpty(props.warehouseList.data) && props.warehouseList.data.map((x) => {
    let data = { value: x._id, label: x.businessName };
    return data;
  });

  const handleChangeIncCompany = (selectedOption) => {
    setIncCompanyId(selectedOption.value);
    setIncCompanyName(selectedOption.label);
  };
  const getIncCompanyList = !_.isEmpty(props.insuranceCompanyDataList) && props.insuranceCompanyDataList.map((x) => {
    let data = { value: x._id, label: x.companyName };
    return data;
  });

  const handleChangeType = (selectedOption) => {
    setTypeVale(selectedOption.value);
    setTypeName(selectedOption.label);
  };
  const type = [
    { value: "Repair", label: "Repair" },
    { value: "Replace", label: "Replace" },
  ];

  useEffect(() => {
    props.setHeading("Add Insurance Service");
    props.getWarehouseApi()
    props.GetInsuranceCompanyApi()
  }, []);

  return (
    <>
      {props.isFetchingWarehouseList || props.isAddingInsuranceService || props.isFetchingInsuranceCompany ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label> <b>Select Business Location *</b> </Form.Label>
                    <Select
                      placeholder="Select Business Location"
                      onChange={handleChangeWarehouse}
                      options={getWarehouseList}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label> <b>Select Insurance Company *</b> </Form.Label>
                    <Select
                      placeholder="Select Insurance Company"
                      onChange={handleChangeIncCompany}
                      options={getIncCompanyList}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Vehicle Number *</b></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Vehicle Number"
                      onChange={(e) => setVehicleNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label> <b>Vehicle Company *</b> </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Vehicle Company"
                      onChange={(e) => setVehicleMade(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Vehicle Model *</b></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Vehicle Model"
                      onChange={(e) => setVehicleModel(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label> <b>Customer Name *</b> </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Customer Name"
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Customer Number *</b></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Customer Number"
                      onChange={(e) => setCustomerNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Customer Email </b></Form.Label>
                    <Form.Control
                      type='text'
                      placeholder="Customer Email"
                      onChange={(e) => setCustomerEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row >
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label> <b> Employee Name </b> </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Employee Name"
                      onChange={(e) => setEmployeeName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label> <b>Date </b> </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Date"
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Select Type *</b></Form.Label>
                    <Select
                      placeholder="Select Type"
                      onChange={handleChangeType}
                      options={type}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Customer ID Number *</b></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Customer ID Number"
                      onChange={(e) => setCustomerIDNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label> <b>Remarks </b></Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      rows={3}
                      placeholder="Remarks"
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl="12" lg="12" md="12" sm="12" xs="12">
                  <Form.Group className="mb-3">
                    <Form.Label> <b style={{ fontSize: "large" }}> Upload Images </b> </Form.Label>
                    <ImageUploader
                      withIcon={false}
                      buttonText="Upload Images"
                      onChange={onDrop}
                      imgExtension={[".jpg", ".jpeg", ".png"]}
                      maxFileSize={999999999999999999999}
                      singleImage={false}
                      withPreview={true}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div style={{ marginTop: '20px', textAlign: 'center' }}>
                {_.isEmpty(businessId) || _.isEmpty(insuranceCompanyId) || _.isEmpty(vehicleNumber) || _.isEmpty(vehicleMade) || _.isEmpty(vehicleModel) || _.isEmpty(customerName) || _.isEmpty(customerNumber) || _.isEmpty(customerIDNumber) ? (
                  <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                ) : (
                  <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveInsuranceService}> SAVE </Button>
                )}
              </div>

            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    AddInsuranceServiceApi: (data, handleVisible, handleDangerVisible) => dispatch(AddInsuranceServiceApi(data, handleVisible, handleDangerVisible)),
    uploadImageApi: (data) => dispatch(uploadImageApi(data)),
    getWarehouseApi: () => dispatch(getWarehouseApi()),
    GetInsuranceCompanyApi: () => dispatch(GetInsuranceCompanyApi()),
    setHeading: (data) => dispatch(setHeading(data)),
    resetReducer: () => dispatch(resetReducer()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isAddingInsuranceService: state.InsuranceServiceReducer.isAddingInsuranceService,
  warehouseList: state.WarehouseReducer.warehouseList,
  isFetchingWarehouseList: state.WarehouseReducer.isFetchingWarehouseList,
  UploadImageURL: state.UploadImageReducer.UploadImageURL,
  insuranceCompanyDataList: state.InsuranceCompanyReducer.insuranceCompanyDataList,
  isFetchingInsuranceCompany: state.InsuranceCompanyReducer.isFetchingInsuranceCompany,
});


export default connect(mapStateToProps, mapDispatchToProps)(AddInsuranceService);
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from 'lodash'
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Select from "react-select";
import ImageUploader from "react-images-upload";
import { AddProductApi, resetReducer } from "./productAction";
import { uploadImageApi } from "../../setting/uploadImage/uploadImageAction";
import { getWarehouseApi } from "../../setting/BusinessLocation/BusinessLocationAction";
import { setHeading } from "../../../accounts/account.actions";


const AddProduct = (props) => {
  const [product, setProduct] = useState("");
  const [salePrice, setsalePrice] = useState();
  const [code, setCode] = useState("");
  const [minimumBalance, setMinimumBalance] = useState("");
  const [maximumBalance, setMaximumBalance] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [productQuantity, setProductQuantity] = useState();
  const [productDesc, setProductDesc] = useState()
  const [businessId, setbusinessId] = useState();
  const [warehouseName, setWarehouseName] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  function SaveProduct() {
    props.AddProductApi({
      businessId: businessId,
      warehouseName: warehouseName,
      code: code,
      name: product,
      desc: productDesc,
      salePrice: salePrice,
      purchasePrice: purchasePrice,
      maximumBalance: maximumBalance,
      minimumBalance: minimumBalance,
      productBalance: productQuantity,
      images: props.UploadImageURL[0].file,
    },
      handleVisible,
      handleDangerVisible
    );
    setProduct("")
    setsalePrice("")
    setCode("")
    setMinimumBalance("")
    setMaximumBalance("")
    setPurchasePrice("")
    setProductQuantity("")
    setProductDesc("")
    setbusinessId("")
    props.resetReducer()
  }

  const onDrop = (pictureFiles) => {
    props.uploadImageApi({
      image: pictureFiles[0],
    });
  };

  const handleChangeWarehouse = (selectedOption) => {
    setbusinessId(selectedOption.value);
    setWarehouseName(selectedOption.label);
  };
  const getWarehouseList = !_.isEmpty(props.warehouseList.data) && props.warehouseList.data.map((x) => {
    let data = { value: x._id, label: x.businessName };
    return data;
  });

  useEffect(() => {
    props.setHeading("New Product");
    props.getWarehouseApi()
  }, []);

  return (
    <>
      {props.isFetchingWarehouseList || props.isAddingProduct ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Select Business Location *</b>
                    </Form.Label>
                    <Select
                      placeholder="Select Business Location"
                      onChange={handleChangeWarehouse}
                      options={getWarehouseList}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Code </b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Code"
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Product Name *</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Product Name"
                      onChange={(e) => setProduct(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Product Description </b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Product Description"
                      onChange={(e) => setProductDesc(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Purchase Price *</b>
                    </Form.Label>
                    <Form.Control
                      type="Number"
                      placeholder="Purchase Price"
                      onChange={(e) => setPurchasePrice(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Sale Price *</b>
                    </Form.Label>
                    <Form.Control
                      type="Number"
                      placeholder="Sale Price"
                      onChange={(e) => setsalePrice(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Product Quantity </b>
                    </Form.Label>
                    <Form.Control
                      type="Number"
                      placeholder="Product Quantity"
                      onChange={(e) => setProductQuantity(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row >
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Minimum Balance </b>
                    </Form.Label>
                    <Form.Control
                      type="Number"
                      placeholder="Minimum Balance"
                      onChange={(e) => setMinimumBalance(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Maximum Balance </b>
                    </Form.Label>
                    <Form.Control
                      type="Number"
                      placeholder="Maximum Balance"
                      onChange={(e) => setMaximumBalance(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl="12" lg="12" md="12" sm="12" xs="12">
                  <Form.Group className="mb-3">
                    <Form.Label><b style={{ fontSize: "large" }}> Upload Product Image </b> </Form.Label>
                    <ImageUploader
                      withIcon={false}
                      buttonText="Upload Product Image"
                      onChange={onDrop}
                      // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                      imgExtension={[".jpg", ".jpeg", ".png"]}
                      maxFileSize={999999999999999999999}
                      singleImage={true}
                      withPreview={true}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div style={{ marginTop: '20px', textAlign: 'center' }}>
                {_.isEmpty(product) || _.isEmpty(businessId) || _.isEmpty(purchasePrice) || _.isEmpty(salePrice) ? (
                  <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                ) : (
                  <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveProduct}> SAVE </Button>
                )}
              </div>

            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    AddProductApi: (data, handleVisible, handleDangerVisible) => dispatch(AddProductApi(data, handleVisible, handleDangerVisible)),
    uploadImageApi: (data) => dispatch(uploadImageApi(data)),
    getWarehouseApi: () => dispatch(getWarehouseApi()),
    setHeading: (data) => dispatch(setHeading(data)),
    resetReducer: () => dispatch(resetReducer()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isAddingProduct: state.ProductReducer.isAddingProduct,
  warehouseList: state.WarehouseReducer.warehouseList,
  isFetchingWarehouseList: state.WarehouseReducer.isFetchingWarehouseList,
  UploadImageURL: state.UploadImageReducer.UploadImageURL,
});


export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
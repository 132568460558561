import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const StoreLedgerDataTable = (props) => {
  return (
    <>
      {props.isFetchingSearchInventoryLedger ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Loading...
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div style={{ textAlign: 'center', marginBottom: '35px' }}>
              <Link to="/store/storeLedger">
                <Button variant="contained" endIcon={<ArrowBackIcon />} size="large" > BACK TO SEARCH </Button>
              </Link>
            </div>

            <div className="main">
              <h5>Product Name: {props.searchInventoryLedgerList.productName}</h5>
              <h5>Total Add Quantity: {props.searchInventoryLedgerList.addQuantity}</h5>
              <h5>Total Less Quantity: {props.searchInventoryLedgerList.lessQuantity}</h5>
              <h5>Closing Balance: {props.searchInventoryLedgerList.closing_balance}</h5>
              <MaterialTable
                title={`${props.searchInventoryLedgerList.productName}`}
                columns={[
                  { title: "Date", field: "transactionDate" },
                  { title: "Description", field: "description" },
                  { title: "Opening Balance", field: "openingBalance" },
                  { title: "Add Quantity", field: "addQuantity" },
                  { title: "Less Quantity", field: "lessQuantity" },
                  { title: "Closing Balance", field: "closingBalance" },
                  // { title: "Date", field: "vocDate", render: (rowData) => moment(rowData.vocDate).format("L") },
                ]}
                data={props.searchInventoryLedgerList.data}
                options={{
                  actionsColumnIndex: -1,
                  filtering: false,
                  exportButton: true,
                  paging: true,
                  pageSize: 50,
                  emptyRowsWhenPaging: false,
                  pageSizeOptions: [50, 100, 150, 200],
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#00BBBB",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({
  searchInventoryLedgerList: state.StoreLegderReducer.searchInventoryLedgerList,
  isFetchingSearchInventoryLedger: state.StoreLegderReducer.isFetchingSearchInventoryLedger,
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreLedgerDataTable);
const initialState = {
    searchInventoryLedgerListReport: [],
    isFetchingSearchInventoryLedgerReport: false,
    searchInventoryLedgerList: [],
    isFetchingSearchInventoryLedger: false,
};

const StoreLegderReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_SEARCH_PRODUCT":
            return { ...state, isFetchingSearchInventoryLedger: true };
        case "SUCCESS_SEARCH_PRODUCT":
            return { ...state, searchInventoryLedgerList: action.payload, isFetchingSearchInventoryLedger: false, };
        case "ERROR_SEARCH_PRODUCT":
            return { ...state, isFetchingSearchInventoryLedger: false };



        case "REQUEST_SEARCH_INVENTORY_REPORT":
            return { ...state, isFetchingSearchInventoryLedgerReport: true };
        case "SUCCESS_SEARCH_INVENTORY_REPORT":
            return { ...state, searchInventoryLedgerListReport: action.payload, isFetchingSearchInventoryLedgerReport: false, };
        case "ERROR_SEARCH_INVENTORY_REPORT":
            return { ...state, isFetchingSearchInventoryLedgerReport: false };


        default:
            return state;
    }
};
export default StoreLegderReducer;

import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from 'lodash'
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Select from "react-select";
import ImageUploader from "react-images-upload";
import { AddCashServiceApi, resetReducer } from "../CashService/cashServiceAction";
import { uploadImageApi } from "../../setting/uploadImage/uploadImageAction";
import { getWarehouseApi } from "../../setting/BusinessLocation/BusinessLocationAction";
import { setHeading } from "../../../accounts/account.actions";


const AddCashService = (props) => {
  const [businessId, setbusinessId] = useState();
  const [warehouseName, setWarehouseName] = useState();
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleMade, setVehicleMade] = useState();
  const [vehicleModel, setVehicleModel] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [employeeName, setEmployeeName] = useState();
  const [remarks, setRemarks] = useState()
  const [date, setDate] = useState()
  const [typeValue, setTypeVale] = useState();
  const [typeName, setTypeName] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  function SaveCashService() {
    props.AddCashServiceApi({
      businessId: businessId,
      images: props.UploadImageURL,
      vehicleNumber: vehicleNumber,
      vehicleMade: vehicleMade,
      vehicleModel: vehicleModel,
      customerName: customerName,
      customerNumber: customerNumber,
      customerEmail: customerEmail,
      employeeName: employeeName,
      remarks: remarks,
      date: date,
      typeValue: typeValue,
      serviceType: 'Cash Service'
    },
      handleVisible,
      handleDangerVisible
    );
    setbusinessId("")
    setVehicleNumber("")
    setVehicleMade("")
    setVehicleModel("")
    setCustomerName("")
    setCustomerNumber("")
    setCustomerEmail("")
    setEmployeeName("")
    setRemarks("")
    setDate("")
    setTypeVale("")
    props.resetReducer()
  }

  const onDrop = (pictureFiles) => {
    props.uploadImageApi({
      image: pictureFiles[0],
    });
  };

  const handleChangeWarehouse = (selectedOption) => {
    setbusinessId(selectedOption.value);
    setWarehouseName(selectedOption.label);
  };
  const getWarehouseList = !_.isEmpty(props.warehouseList.data) && props.warehouseList.data.map((x) => {
    let data = { value: x._id, label: x.businessName };
    return data;
  });

  const handleChangeType = (selectedOption) => {
    setTypeVale(selectedOption.value);
    setTypeName(selectedOption.label);
  };
  const type = [
    { value: "Repair", label: "Repair" },
    { value: "Replace", label: "Replace" },
  ];


  useEffect(() => {
    props.setHeading("Add Cash Service");
    props.getWarehouseApi()
  }, []);

  return (
    <>
      {props.isFetchingWarehouseList || props.isAddingCashService ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Select Business Location *</b></Form.Label>
                    <Select
                      placeholder="Select Business Location"
                      onChange={handleChangeWarehouse}
                      options={getWarehouseList}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Vehicle Number *</b></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Vehicle Number"
                      onChange={(e) => setVehicleNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Vehicle Made *</b></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Vehicle Made"
                      onChange={(e) => setVehicleMade(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Vehicle Model *</b></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Vehicle Model"
                      onChange={(e) => setVehicleModel(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label> <b>Customer Name *</b> </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Customer Name"
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Customer Number *</b></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Customer Number"
                      onChange={(e) => setCustomerNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Customer Email *</b></Form.Label>
                    <Form.Control
                      type='text'
                      placeholder="Customer Email"
                      onChange={(e) => setCustomerEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row >
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Employee Name</b></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Employee Name"
                      onChange={(e) => setEmployeeName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Date</b></Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Date"
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label><b>Select Type *</b></Form.Label>
                    <Select
                      placeholder="Select Type"
                      onChange={handleChangeType}
                      options={type}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label> <b>Remarks </b></Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      rows={3}
                      placeholder="Remarks"
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl="12" lg="12" md="12" sm="12" xs="12">
                  <Form.Group className="mb-3">
                    <Form.Label> <b style={{ fontSize: "large" }}> Upload Images </b> </Form.Label>
                    <ImageUploader
                      withIcon={true}
                      buttonText="Upload Images"
                      onChange={onDrop}
                      // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                      imgExtension={[".jpg", ".jpeg", ".png"]}
                      maxFileSize={999999999999999999999}
                      singleImage={false}
                      withPreview={true}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div style={{ marginTop: '20px', textAlign: 'center' }}>
                {_.isEmpty(businessId) || _.isEmpty(vehicleNumber) || _.isEmpty(vehicleMade) || _.isEmpty(vehicleModel) || _.isEmpty(customerEmail) || _.isEmpty(customerName) || _.isEmpty(customerNumber) ? (
                  <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                ) : (
                  <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveCashService}> SAVE </Button>
                )}
              </div>

            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    AddCashServiceApi: (data, handleVisible, handleDangerVisible) => dispatch(AddCashServiceApi(data, handleVisible, handleDangerVisible)),
    uploadImageApi: (data) => dispatch(uploadImageApi(data)),
    getWarehouseApi: () => dispatch(getWarehouseApi()),
    setHeading: (data) => dispatch(setHeading(data)),
    resetReducer: () => dispatch(resetReducer()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isAddingUploadImage: state.UploadImageReducer.isAddingUploadImage,
  isAddingCashService: state.CashServiceReducer.isAddingCashService,
  warehouseList: state.WarehouseReducer.warehouseList,
  isFetchingWarehouseList: state.WarehouseReducer.isFetchingWarehouseList,
  UploadImageURL: state.UploadImageReducer.UploadImageURL,
});


export default connect(mapStateToProps, mapDispatchToProps)(AddCashService);
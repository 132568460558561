import axios from "axios";
// export const baseURL = `http://localhost:3002/v1`;
// export const baseURLIO = `http://localhost:3002`;

export const baseURL = `https://siraj.alfarsi-group.com/v1`;
export const baseURLIO = `https://siraj.alfarsi-group.com`;

export const headers = {
  "Content-Type": "application/json",
  //'Access-Control-Allow-Origin': '*',
  Authorization: `Bearer ${window.localStorage.getItem("token")}`,
};
const instance = axios.create({
  baseURL: baseURL,
  headers: headers,
  responseType: "json",
});

function createAxiosResponseInterceptor(axiosInstance) {
  const interceptor = axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }
      axiosInstance.interceptors.response.eject(interceptor);
      window.localStorage.clear();
      window.location.replace("/login");
      return Promise.reject(error);
    }
  );
}

createAxiosResponseInterceptor(instance);

export default instance;

import API from "../../../../../global/api";


export const requestAddCompany = () => {
    return {
        type: "REQUEST_ADD_COMPANY",
    };
};
export const successAddCompany = (data) => {
    return {
        type: "SUCCESS_ADD_COMPANY",
        payload: data,
    };
};
export const errorAddCompany = () => {
    return {
        type: "ERROR_ADD_COMPANY",
    };
};
export const addCompanyApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddCompany());
        API.post(`/company`, data).then((res) => {
            dispatch(successAddCompany(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddCompany());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestCompanyData = () => {
    return {
        type: "REQUEST_COMPANY_GET_DATA",
    };
};
export const successCompanyData = (data) => {
    return {
        type: "SUCCESS_COMPANY_GET_DATA",
        payload: data,
    };
};
export const errorCompanyGetData = () => {
    return {
        type: "ERROR_COMPANY_GET_DATA",
    };
};
export const GetCompanyApi = () => {
    return (dispatch) => {
        dispatch(requestCompanyData());
        API.get(`/company?page=0&limit=1000`).then((res) => {
            let getData = res.data;
            dispatch(successCompanyData(getData));
        }).catch((error) => {
            dispatch(errorCompanyGetData());
        })
    };
};


export const DeleteRequestCompany = () => {
    return {
        type: "DELETE_REQUEST_COMPANY",
    };
};
export const DeleteSuccessCompany = (user_id) => {
    return {
        type: "DELETE_SUCCESS_COMPANY",
        user_id: user_id,
    };
};
export const DeleteErrorCompany = () => {
    return {
        type: "DELETE_ERROR_COMPANY",
    };
};
export const DeleteCompanyApi = (companyId) => {
    return dispatch => {
        dispatch(DeleteRequestCompany());
        API.delete(`/company/${companyId}`).then(res => {
            dispatch(DeleteSuccessCompany(companyId))
        }).catch((error) => {
            dispatch(DeleteErrorCompany())
        });
    }
};


export const UpdateRequestCompany = () => {
    return {
        type: "UPDATE_REQUEST_COMPANY",
    };
};
export const UpdateSuccessCompany = (data) => {
    return {
        type: "UPDATE_SUCCESS_COMPANY",
        payload: data,
    };
};
export const UpdateErrorCompany = () => {
    return {
        type: "UPDATE_ERROR_COMPANY",
    };
};
export const UpdateCompanyApi = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestCompany());
        API.put(`/company/${oldData._id}`, {
            companyName: data.companyName,
            location: data.location,
            description: data.description,
            // image: props.UploadImageURL.file,
            ntn: data.ntn,
            phone: data.phone,
            email: data.email,
            strn: data.strn,
        }, {
        }).then(res => {
            dispatch(UpdateSuccessCompany([data]))
        }).catch((error) =>
            dispatch(UpdateErrorCompany()));
    }
};
const initialState = {
    UploadImageURL: [],
    isAddingUploadImage: false,
};

const UploadImageReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_IMAGE":
            return { ...state, isAddingUploadImage: true };
        case "SUCCESS_ADD_IMAGE":
            return { ...state, UploadImageURL: state.UploadImageURL.concat(action.payload), isAddingUploadImage: false, };
        case "ERROR_ADD_IMAGE":
            return { ...state, isAddingUploadImage: false };
            
        case "RESET_URL":
            return { ...state, UploadImageURL: [] }

        default:
            return state;
    }
};
export default UploadImageReducer;
import API from "../../../../../global/api";



export const requestAddImage = () => {
    return {
        type: "REQUEST_ADD_IMAGE",
    };
};
export const successAddImage = (data) => {
    return {
        type: "SUCCESS_ADD_IMAGE",
        payload: data,
    };
};
export const errorAddImage = () => {
    return {
        type: "ERROR_ADD_IMAGE",
    };
};
export const uploadImageApi = (data) => {
    return (dispatch) => {
        const formData = new FormData();
        // formData.append("image", get(data, 'image'));
        formData.append("image", data.image)
        dispatch(requestAddImage());
        API.post(`/files/image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            let getData = res.data;
            dispatch(successAddImage(getData));
        }).catch((error) => {
            dispatch(errorAddImage());
        })
    }
};
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert";
import { Row, Col, Form, Container } from "react-bootstrap";
import { getPermissions, addPermissions, } from "./PermissionsActions";
import { getUserRes } from "../../setting/AddUser/user.actions";
import { setHeading } from "../../../accounts/account.actions";
import { isEmpty, find } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const UserPermissions = (props) => {
  const [users, setUsers] = useState("");
  const [mainCategory, setMainCategory1] = useState();
  const [checkedData, setChecked] = useState([]);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


  const handleGarage = (event) => {
    setMainCategory1(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) && props.permissionsList[0].permission_cats.map((x) => {
        updatedList.push({
          permissionsCatName: x.permissionsCatName,
          permissionsName: props.permissionsList[0].permissionsName,
        });
        !isEmpty(x.permissionSubCats) && x.permissionSubCats.map((sub) => {
          updatedList.push({
            permissionsCatName: x.permissionsCatName,
            permissionsName: props.permissionsList[0].permissionsName,
            permissionsSubCatName: sub.permissionsSubCatName,
          });
        });
      });
      updatedList.push({
        permissionsCatName: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) && props.permissionsList[0].permission_cats.map((x) => {
        !isEmpty(x.permissionSubCats) && x.permissionSubCats.map((sub) => {
          updatedList = updatedList.filter((e) => e.permissionsCatName != x.permissionsCatName);
          updatedList = updatedList.filter(
            (e) => e.permissionsSubCatName != sub.permissionsSubCatName
          );
        });
      });
      updatedList = updatedList.filter((e) => e.permissionsCatName != `${event.target.value}`);
    }
    setChecked(updatedList);
  };
  const handleCheck = (event) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      let data = !isEmpty(props.permissionsList) &&
        props.permissionsList[0].permission_cats.find((x) => x.permissionsCatName == event.target.value).permissionSubCats.map((y) => {
          return y.permissionsSubCatName;
        });
      isEmpty(data) ? updatedList.push({
        permissionsCatName: event.target.value,
        permissionsName: props.permissionsList[0].permissionsName,
      }) : data.map((z) => {
        updatedList.push({
          permissionsCatName: event.target.value,
          permissionsName: props.permissionsList[0].permissionsName,
          permissionsSubCatName: z,
        });
      });
    } else {
      updatedList = updatedList.filter((e) => e.permissionsCatName != `${event.target.value}`);
    }
    setChecked(updatedList);
  };
  const handleCheckSub = (event, pt) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsCatName: pt.permissionsCatName,
        permissionsName: props.permissionsList[0].permissionsName,
        permissionsSubCatName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter((e) => e.permissionsSubCatName != `${event.target.value}`);
    }
    setChecked(updatedList);
  };


  const handleMasterControl = (event) => {
    setMainCategory1(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) && props.permissionsList[1].permission_cats.map((x) => {
        updatedList.push({
          permissionsCatName: x.permissionsCatName,
          permissionsName: props.permissionsList[1].permissionsName,
        });
        !isEmpty(x.permissionSubCats) && x.permissionSubCats.map((sub) => {
          updatedList.push({
            permissionsCatName: x.permissionsCatName,
            permissionsName: props.permissionsList[1].permissionsName,
            permissionsSubCatName: sub.permissionsSubCatName,
          });
        });
      });
      updatedList.push({
        permissionsCatName: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) && props.permissionsList[1].permission_cats.map((x) => {
        !isEmpty(x.permissionSubCats) && x.permissionSubCats.map((sub) => {
          updatedList = updatedList.filter((e) => e.permissionsCatName != x.permissionsCatName);
          updatedList = updatedList.filter(
            (e) => e.permissionsSubCatName != sub.permissionsSubCatName
          );
        });
      });
      updatedList = updatedList.filter((e) => e.permissionsCatName != `${event.target.value}`);
    }
    setChecked(updatedList);
  };
  const handleCheckMasterControl = (event) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      let data = !isEmpty(props.permissionsList) &&
        props.permissionsList[1].permission_cats.find((x) => x.permissionsCatName == event.target.value).permissionSubCats.map((y) => {
          return y.permissionsSubCatName;
        });
      isEmpty(data) ? updatedList.push({
        permissionsCatName: event.target.value,
        permissionsName: props.permissionsList[1].permissionsName,
      }) : data.map((z) => {
        updatedList.push({
          permissionsCatName: event.target.value,
          permissionsName: props.permissionsList[1].permissionsName,
          permissionsSubCatName: z,
        });
      });
    } else {
      updatedList = updatedList.filter((e) => e.permissionsCatName != `${event.target.value}`);
    }
    setChecked(updatedList);
  };
  const handleCheckSubMasterControl = (event, pt) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsCatName: pt.permissionsCatName,
        permissionsName: props.permissionsList[1].permissionsName,
        permissionsSubCatName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter((e) => e.permissionsSubCatName != `${event.target.value}`);
    }
    setChecked(updatedList);
  };


  const handleStore = (event) => {
    setMainCategory1(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) && props.permissionsList[2].permission_cats.map((x) => {
        updatedList.push({
          permissionsCatName: x.permissionsCatName,
          permissionsName: props.permissionsList[2].permissionsName,
        });
        !isEmpty(x.permissionSubCats) && x.permissionSubCats.map((sub) => {
          updatedList.push({
            permissionsCatName: x.permissionsCatName,
            permissionsName: props.permissionsList[2].permissionsName,
            permissionsSubCatName: sub.permissionsSubCatName,
          });
        });
      });
      updatedList.push({
        permissionsCatName: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) && props.permissionsList[2].permission_cats.map((x) => {
        !isEmpty(x.permissionSubCats) && x.permissionSubCats.map((sub) => {
          updatedList = updatedList.filter((e) => e.permissionsCatName != x.permissionsCatName);
          updatedList = updatedList.filter(
            (e) => e.permissionsSubCatName != sub.permissionsSubCatName
          );
        });
      });
      updatedList = updatedList.filter((e) => e.permissionsCatName != `${event.target.value}`);
    }
    setChecked(updatedList);
  };
  const handleCheckStore = (event) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      let data = !isEmpty(props.permissionsList) &&
        props.permissionsList[2].permission_cats.find((x) => x.permissionsCatName == event.target.value).permissionSubCats.map((y) => {
          return y.permissionsSubCatName;
        });
      isEmpty(data) ? updatedList.push({
        permissionsCatName: event.target.value,
        permissionsName: props.permissionsList[2].permissionsName,
      }) : data.map((z) => {
        updatedList.push({
          permissionsCatName: event.target.value,
          permissionsName: props.permissionsList[2].permissionsName,
          permissionsSubCatName: z,
        });
      });
    } else {
      updatedList = updatedList.filter((e) => e.permissionsCatName != `${event.target.value}`);
    }
    setChecked(updatedList);
  };
  const handleCheckSubStore = (event, pt) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsCatName: pt.permissionsCatName,
        permissionsName: props.permissionsList[2].permissionsName,
        permissionsSubCatName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter((e) => e.permissionsSubCatName != `${event.target.value}`);
    }
    setChecked(updatedList);
  };


  const SavePermissions = () => {
    props.addPermissions({
      user: users,
      permission: checkedData,
    },
      handleVisible,
      handleDangerVisible
    );
    setUsers("")
    setChecked("")
  };
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 3000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (error) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 3000);
    Swal({
      title: "Error",
      text: error,
      icon: "error",
      button: "Ok",
    });
  };


  const handleChangeUsers = (selectedOption) => {
    setUsers(selectedOption.value);
  };
  const getUsersData = !isEmpty(props.userList) && props.userList.map((x) => {
    let data = { value: x._id, label: x.firstName };
    return data;
  });


  useEffect(() => {
    props.getUserRes();
    props.getPermissions();
    props.setHeading("Permission")
  }, []);

  return (
    <>
      {props.isFetchingUser || props.isFetchingPermissionsList || props.isAddingPermissions ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingPermissions ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label><b>Select User *</b></Form.Label>
                  <Select
                    placeholder="Select User"
                    onChange={(e) => handleChangeUsers(e)}
                    options={getUsersData}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row style={{ marginTop: '30px' }}>

              <Col lg="3" md="3" sm="3">
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    value={!isEmpty(props.permissionsList) && props.permissionsList[0].permissionsName}
                    onChange={(event) => handleGarage(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) && props.permissionsList[0].permissionsName}
                  </label>
                </div>
                {!isEmpty(props.permissionsList) && props.permissionsList[0].permission_cats.map((inv, index) => {
                  return (
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <div style={{ marginLeft: "15%" }}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={inv.permissionsCatName}
                            checked={!isEmpty(checkedData) ? find(checkedData, (x) => x.permissionsCatName == inv.permissionsCatName) : false}
                            id="flexCheckDefault"
                            onChange={(e) => handleCheck(e)}
                          />
                          <label className="form-check-label" for="flexCheckDefault">
                            {inv.permissionsCatName}
                          </label>
                          {!isEmpty(inv.permissionSubCats) &&
                            inv.permissionSubCats.map((sub, index) => {
                              return (
                                <Row>
                                  <Col lg="12" md="12" sm="12">
                                    <div style={{ marginLeft: "15%" }}>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={sub.permissionsSubCatName}
                                        checked={!isEmpty(checkedData) ? find(checkedData, (x) => x.permissionsSubCatName == sub.permissionsSubCatName) : false}
                                        id="flexCheckDefault"
                                        onChange={(e) => handleCheckSub(e, inv)}
                                      />
                                      <label className="form-check-label" for="flexCheckDefault" >
                                        {sub.permissionsSubCatName}
                                      </label>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </Col>


              <Col lg="3" md="3" sm="3">
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    value={!isEmpty(props.permissionsList) && props.permissionsList[1].permissionsName}
                    onChange={(event) => handleMasterControl(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) && props.permissionsList[1].permissionsName}
                  </label>
                </div>
                {!isEmpty(props.permissionsList) && props.permissionsList[1].permission_cats.map((inv, index) => {
                  return (
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <div style={{ marginLeft: "15%" }}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={inv.permissionsCatName}
                            checked={!isEmpty(checkedData) ? find(checkedData, (x) => x.permissionsCatName == inv.permissionsCatName) : false}
                            id="flexCheckDefault"
                            onChange={(e) => handleCheckMasterControl(e)}
                          />
                          <label className="form-check-label" for="flexCheckDefault"> {inv.permissionsCatName}</label>
                          {!isEmpty(inv.permissionSubCats) &&
                            inv.permissionSubCats.map((sub, index) => {
                              return (
                                <Row>
                                  <Col lg="12" md="12" sm="12">
                                    <div style={{ marginLeft: "15%" }}>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={sub.permissionsSubCatName}
                                        checked={!isEmpty(checkedData) ? find(checkedData, (x) => x.permissionsSubCatName == sub.permissionsSubCatName) : false}
                                        id="flexCheckDefault"
                                        onChange={(e) => handleCheckSubMasterControl(e, inv)}
                                      />
                                      <label className="form-check-label" for="flexCheckDefault" > {sub.permissionsSubCatName}</label>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </Col>


              <Col lg="3" md="3" sm="3">
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    value={!isEmpty(props.permissionsList) && props.permissionsList[2].permissionsName}
                    onChange={(event) => handleStore(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) && props.permissionsList[2].permissionsName}
                  </label>
                </div>
                {!isEmpty(props.permissionsList) && props.permissionsList[2].permission_cats.map((inv, index) => {
                  return (
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <div style={{ marginLeft: "15%" }}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={inv.permissionsCatName}
                            checked={!isEmpty(checkedData) ? find(checkedData, (x) => x.permissionsCatName == inv.permissionsCatName) : false}
                            id="flexCheckDefault"
                            onChange={(e) => handleCheckStore(e)}
                          />
                          <label className="form-check-label" for="flexCheckDefault"> {inv.permissionsCatName}</label>
                          {!isEmpty(inv.permissionSubCats) && inv.permissionSubCats.map((sub, index) => {
                            return (
                              <Row>
                                <Col lg="12" md="12" sm="12">
                                  <div style={{ marginLeft: "15%" }}>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={sub.permissionsSubCatName}
                                      checked={!isEmpty(checkedData) ? find(checkedData, (x) => x.permissionsSubCatName == sub.permissionsSubCatName) : false}
                                      id="flexCheckDefault"
                                      onChange={(e) => handleCheckSubStore(e, inv)}
                                    />
                                    <label className="form-check-label" for="flexCheckDefault" > {sub.permissionsSubCatName}</label>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </Col>

            </Row>

            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              {isEmpty(users) ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SavePermissions}> SAVE </Button>
              )}
            </div>

          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserRes: () => dispatch(getUserRes()),
    getPermissions: () => dispatch(getPermissions()),
    addPermissions: (data, handleVisible, handleDangerVisible) => dispatch(addPermissions(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data))
  };
};

const mapStateToProps = (state, ownProps) => ({
  isAddingPermissions: state.PermissionReducer.isAddingPermissions,
  isFetchingUser: state.UserReducer.isFetchingUser,
  userList: state.UserReducer.userList,
  permissionsList: state.PermissionReducer.permissionsList,
  isFetchingPermissionsList: state.PermissionReducer.isFetchingPermissionsList,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissions);